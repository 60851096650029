import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from './config';
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [toastrMsg, setToastrMsg] = useState(null);
    const [toastrError, setToastrError] = useState(null);
    const [token, setToken] = useState(null);
    const [isRegistered, setIsRegistered] = useState(false);
    const [contactsList, setContactsList] = useState(null);
    const [usersList, setUsersList] = useState(null);
    const [messages, setMessages] = useState(null);
    const [cashFlow, setCashFlow] = useState(null);
    const [accountData, setAccountData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [externalData, setExternalData] = useState(null);
    const [loggedOut, setLoggedOut] = useState(false);
    const [bulkDataMsg, setBulkDataMsg] = useState(null);
    const [bulkDataError, setBulkDataError] = useState(null);
    const [vendorAccounts, setVendorAccounts] = useState(null);
    const [toastrOtpMsg, setToastrOtpMsg] = useState(null);
    const [toastrOtpError, setToastrOtpError] = useState(null);
    const [toastrEmailMsg, setToastrEmailMsg] = useState(null);
    const [toastrEmailError, setToastrEmailError] = useState(null);
    const [hasGSigned, setHasGSigned] = useState(null);
    const [googleRegistered, setGoogleRegistered] = useState(false);
    const [gRegistered, setGRegistered] = useState(null);
    const [companyData, setCompanyData] = useState(null);
    const [companyLocations, setCompanyLocations] = useState(null);
    const [taskTypes, setTaskTypes] = useState(null);
    const [taskList, setTaskList] = useState(null);
    const [cashFilterList, setCashFilterList] = useState(null);
    const [pages, setPages] = useState(null);
    const [attendanceList, setAttendanceList] = useState(null);
    const [profileData, setProfileData] = useState(null);
    const [userType, setUserType] = useState(null);
    const [userProfileData, setUserProfileData] = useState(null);
    const [markeed, setMarked] = useState(null);
    const [toastrAccountMsg, setToastrAccountMsg] = useState(null);
    const [toastrAccountError, setToastrAccountError] = useState(null);
    const [toastrTaskMsg, setToastrTaskMsg] = useState(null);
    const [toastrTaskError, setToastrTaskError] = useState(null);
    const [userLocations, setUserLocations] = useState(null);
    const [dashboardData, setDashboardData] = useState(null);
    const [attendanceReport, setAttendanceReport] = useState(null);
    const [otpSent, setOtpSent] = useState(false);
    const [cred, setCred] = useState(null);
    const [userCreds, setUserCreds] = useState(null);

    const register = (email, name, company, gSign = false) => {
        axios
            .post(`${BASE_URL}/api/register/`, { email, name, company, gSign })
            .then((res) => {
                setLoggedOut(false);
                let incomingData = res.data;

                if (incomingData.success) {
                    setToastrMsg(incomingData.msg);
                    if (gSign) {
                        localStorage.setItem(
                            'userInfo',
                            JSON.stringify(incomingData)
                        );
                        setGoogleRegistered(true);
                    } else {
                        setIsRegistered(true);
                    }
                    setToastrError(null);
                } else {
                    setToastrError(incomingData.msg);
                    setToastrMsg(null);
                }
            })
            .catch((err) => {
                setToastrError('An error occured. Please try again');
                setToastrMsg(null);
            });
    };

    const sendOtp = (email) => {
        axios
            .post(`${BASE_URL}/api/send-otp/`, { email })
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    setOtpSent(true);
                    setToastrEmailMsg(incomingData.msg);
                    setToastrEmailError(null);
                } else if (!incomingData.success) {
                    setToastrEmailError(incomingData.msg);
                    setToastrEmailMsg(null);
                }
            })
            .catch((err) => {
                setToastrError('An error occured. Please try again');
                setToastrMsg(null);
            });
    };

    const verifyOtp = (email, otp) => {
        axios
            .post(`${BASE_URL}/api/verify-otp/`, { email, otp })
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    setToastrOtpMsg(incomingData.msg);
                    setToastrOtpError(null);
                } else if (!incomingData.success) {
                    setToastrOtpError(incomingData.msg);
                    setToastrOtpMsg(null);
                }
            })
            .catch((err) => {
                setToastrError('An error occured. Please try again');
                setToastrMsg(null);
            });
    };

    const setPassword = (email, password) => {
        axios
            .post(`${BASE_URL}/api/set-password/`, { email, password })
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    setToastrMsg(incomingData.msg);
                    setToastrError(null);
                } else if (!incomingData.success) {
                    setToastrError(incomingData.msg);
                    setToastrMsg(null);
                }
            })
            .catch((err) => {
                setToastrError('An error occured. Please try again');
                setToastrMsg(null);
            });
    };

    const resetPassword = (currentPassword, password) => {
        axios
            .post(
                `${BASE_URL}/api/reset-password/`,
                {
                    currentPassword,
                    password,
                },
                {
                    headers: {
                        Authorization: `Token ${
                            JSON.parse(localStorage.getItem('userInfo'))?.token
                        }`,
                    },
                }
            )
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    setCred('yes');
                    setToastrMsg(incomingData.msg);
                    setToastrError(null);
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                    setToastrError(incomingData.msg);
                    setToastrMsg(null);
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
                setToastrError('An error occured. Please try again');
                setToastrMsg(null);
            });
    };

    const checkEmail = async (email) => {
        try {
            const response = await axios.post(`${BASE_URL}/api/check-email/`, {
                email,
            });
            const incomingData = response.data;
            if (incomingData.success) {
                setLoggedOut(false);
                await setLocalStorage(incomingData);
                setToken(incomingData.token);
                setUserType(incomingData.user_type);
                setToastrMsg(incomingData.msg);
                setToastrError(null);
            } else if (!incomingData.success) {
                // Handle unsuccessful login
                setToastrError(incomingData.msg);
                setToastrMsg(null);
            }
        } catch (error) {
            setToastrError('Login Failed');
            setToastrMsg(null);
        }
    };

    const checkGRegister = (email) => {
        axios
            .post(`${BASE_URL}/api/gregister_check/`, { email })
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    localStorage.setItem(
                        'userInfo',
                        JSON.stringify(incomingData)
                    );
                    setToastrMsg(null);
                    setToastrError(null);
                    setHasGSigned('yes');
                } else if (!incomingData.success) {
                    setHasGSigned('no');
                    // setToastrError(incomingData.msg);
                    setToastrError(incomingData.msg);
                    setToastrMsg(null);
                }
            })
            .catch((err) => {
                setHasGSigned('no');
                setToastrError('An error occured');
                setToastrMsg(null);
            });
    };

    async function setLocalStorage(data) {
        localStorage.setItem('userInfo', JSON.stringify(data));
    }

    const login = async (username, password) => {
        try {
            const response = await axios.post(`${BASE_URL}/api/login/`, {
                username,
                password,
            });
            const incomingData = response.data;
            if (incomingData.success) {
                setLoggedOut(false);
                // Update state with authentication details
                await setLocalStorage(incomingData);
                setToken(incomingData.token);
                setUserType(incomingData.user_type);
                setToastrMsg(incomingData.msg);
                setToastrError(null);
            } else if (!incomingData.success) {
                // Handle unsuccessful login
                setToastrError(incomingData.msg);
                setToastrMsg(null);
            }
        } catch (error) {
            setToastrError('Login Failed');
            setToastrMsg(null);
        }
    };

    const checkUserPassword = () => {
        axios
            .get(`${BASE_URL}/api/check_user_password/`, {
                headers: {
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    if (incomingData.cred) {
                        setCred('yes');
                    } else {
                        setCred('no');
                    }
                    setToastrMsg(incomingData.msg);
                    setToastrError(null);
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                    setToastrError(incomingData.msg);
                    setToastrMsg(null);
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
                setToastrError('An error occured. Please try again');
                setToastrMsg(null);
            });
    };

    const getUsersList = () => {
        axios
            .get(`${BASE_URL}/api/users-list/`, {
                headers: {
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    setUsersList(incomingData.users_list);
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
            });
    };

    const addUser = (
        name,
        email,
        employeeId,
        type,
        department,
        designation,
        mode
    ) => {
        axios
            .post(
                `${BASE_URL}/api/add-user/`,
                {
                    name,
                    email,
                    employeeId,
                    type,
                    department,
                    designation,
                    mode,
                },
                {
                    headers: {
                        Authorization: `Token ${
                            JSON.parse(localStorage.getItem('userInfo'))?.token
                        }`,
                    },
                }
            )
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    getUsersList();
                    setToastrMsg(incomingData.msg);
                    setToastrError(null);
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                    setToastrError(incomingData.msg);
                    setToastrMsg(null);
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
                setToastrError('An error occured. Please try again later');
                setToastrMsg(null);
            });
    };

    const addBulkUser = (bulkData, company) => {
        const userBulkData = JSON.stringify(bulkData);
        axios
            .post(
                `${BASE_URL}/api/add-bulk-users/`,
                {
                    userBulkData,
                    company,
                },
                {
                    headers: {
                        Authorization: `Token ${
                            JSON.parse(localStorage.getItem('userInfo'))?.token
                        }`,
                    },
                }
            )
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    getUsersList();
                    setBulkDataError(null);
                    setBulkDataMsg(incomingData.msg);
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                    setBulkDataMsg(null);
                    setBulkDataError(incomingData.msg);
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
                setBulkDataMsg(null);
                setBulkDataError('An error occured');
            });
    };

    const editUser = (formData) => {
        let userId = formData.get('id');
        axios
            .post(
                `${BASE_URL}/api/edit-user/${userId}/`,

                formData,

                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Token ${
                            JSON.parse(localStorage.getItem('userInfo'))?.token
                        }`,
                    },
                }
            )
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    getUsersList();
                    getUserCreds();
                    setToastrMsg(incomingData.msg);
                    setToastrError(null);
                } else if (!incomingData.success) {
                    setToastrError(incomingData.msg);
                    setToastrMsg(null);
                }
            })
            .catch((err) => {
                setToastrError('An error occured. Please try again later');
                setToastrMsg(null);
            });
    };

    const getUserCreds = () => {
        axios
            .get(`${BASE_URL}/api/get_user_creds/`, {
                headers: {
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    setUserCreds(incomingData.creds);
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
            });
    };

    const editUserLocations = (locations, id) => {
        axios
            .post(
                `${BASE_URL}/api/edit_user_locations/`,
                { locations },
                {
                    headers: {
                        Authorization: `Token ${
                            JSON.parse(localStorage.getItem('userInfo'))?.token
                        }`,
                    },
                }
            )
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    getUserProfileInfo(id);
                    setToastrError(null);
                    setToastrMsg(incomingData.msg);
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                    setToastrMsg(null);
                    setToastrError(incomingData.msg);
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
                setToastrMsg(null);
                setToastrError('An error occured');
            });
    };

    const deleteUser = (id) => {
        axios
            .post(
                `${BASE_URL}/api/delete-user/`,
                {
                    id,
                },
                {
                    headers: {
                        Authorization: `Token ${
                            JSON.parse(localStorage.getItem('userInfo'))?.token
                        }`,
                    },
                }
            )
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    setToastrMsg(incomingData.msg);
                    setToastrError(null);
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                    setToastrError(incomingData.msg);
                    setToastrMsg(null);
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
                setToastrError('An error occured. Please try again later');
                setToastrMsg(null);
            });
    };

    // useEffect(() => {
    //   if (userInfo?.userId) {
    //     localStorage.setItem("userInfo", JSON.stringify(userInfo));
    //   }
    // }, [userInfo]);

    const getInputDetails = () => {
        axios
            .get(`${BASE_URL}/api/get_input_details/`, {
                headers: {
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                const incomingData = res.data;
                if (incomingData.success) {
                    setUserData(res.data.users);
                    setAccountData(res.data.accounts);
                    setExternalData(res.data.external);
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
                console.warn(`input detail error: ${err}`);
            });
    };

    const getCompanyDetails = () => {
        axios
            .get(`${BASE_URL}/api/get-company-details/`, {
                headers: {
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    setCompanyData(incomingData.company_detail);
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
            });
    };

    const getCompanyLocations = () => {
        setCompanyLocations(null);
        axios
            .get(`${BASE_URL}/api/get-company-locations/`, {
                headers: {
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                let incomingData = res.data;

                if (incomingData.success) {
                    setCompanyLocations(incomingData.locations);
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
            });
    };

    const getUserLocations = () => {
        setUserLocations(null);
        axios
            .get(`${BASE_URL}/api/get_user_locations/`, {
                headers: {
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                let incomingData = res.data;

                if (incomingData.success) {
                    setUserLocations(incomingData.locations);
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
            });
    };

    const editCompanyInfo = (formData) => {
        axios
            .post(`${BASE_URL}/api/edit-company-details/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    getCompanyDetails();
                    setToastrError(null);
                    setToastrMsg(incomingData.msg);
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                    setToastrMsg(null);
                    setToastrError(incomingData.msg);
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
                setToastrMsg(null);
                setToastrError('An error occured');
            });
    };

    const editComapanyContactInfo = (locations) => {
        axios
            .post(
                `${BASE_URL}/api/edit-company-contact-details/`,
                { locations },
                {
                    headers: {
                        Authorization: `Token ${
                            JSON.parse(localStorage.getItem('userInfo'))?.token
                        }`,
                    },
                }
            )
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    getCompanyDetails();
                    setToastrError(null);
                    setToastrMsg(incomingData.msg);
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                    setToastrMsg(null);
                    setToastrError(incomingData.msg);
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
                setToastrMsg(null);
                setToastrError('An error occured');
            });
    };

    const getAttendance = ({
        user = null,
        date = null,
        reset = null,
        page = null,
    } = {}) => {
        let params = {};
        if (reset) {
            params = {
                reset: '1',
            };
        } else {
            params = {
                user: user,
                date: date,
            };
            if (page) {
                params['page'] = page;
            }
        }
        axios
            .get(`${BASE_URL}/api/get-attendance/`, {
                params: params,
                headers: {
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    setAttendanceList(incomingData.data);
                    setPages(incomingData.num_pages);
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
            });
    };

    const getUserAttendance = ({
        date = null,
        reset = null,
        page = null,
    } = {}) => {
        let params = {};
        if (reset) {
            params = {
                reset: '1',
            };
        } else {
            params = {
                date: date,
            };
            if (page) {
                params['page'] = page;
            }
        }
        axios
            .get(`${BASE_URL}/api/get_user_attendance/`, {
                params: params,
                headers: {
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    setAttendanceList(incomingData.data);
                    setPages(incomingData.num_pages);
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
            });
    };

    const getProfileInfo = () => {
        axios
            .get(`${BASE_URL}/api/get-profile-info/`, {
                headers: {
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    setProfileData(incomingData.data);
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
            });
    };

    const getUserProfileInfo = (id) => {
        axios
            .get(`${BASE_URL}/api/get-user-profile-info/${id}/`, {
                headers: {
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    setUserProfileData(incomingData.data);
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
            });
    };

    const editProfileInfo = (formData) => {
        axios
            .post(`${BASE_URL}/api/edit-profile-info/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    getProfileInfo();
                    setToastrError(null);
                    setToastrMsg(incomingData.msg);
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                    setToastrMsg(null);
                    setToastrError(incomingData.msg);
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
                setToastrMsg(null);
                setToastrError('An error occured');
            });
    };

    const checkLocation = (input, geocodes) => {
        axios
            .get(
                'https://maps.googleapis.com/maps/api/place/autocomplete/json',
                {
                    params: {
                        key: 'AIzaSyAKfXYqO-pm-5sGB_yrgvhPS9Y2cKu7MWE',
                        input: input,
                        location: geocodes,
                        radius: 50,
                        strictbounds: true,
                    },
                }
            )
            .then((res) => {})
            .catch((err) => {});
    };

    const markAttendance = (formData) => {
        const userId = formData.get('id');

        setIsLoading(true);
        axios
            .post(`${BASE_URL}/api/mark-attendance/${userId}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                const incomingData = res.data;
                if (incomingData.success) {
                    setIsLoading(false);
                    setToastrMsg(incomingData.msg);
                    setToastrError(null);
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                    setIsLoading(false);
                    setToastrError(incomingData.msg);
                    setToastrMsg(null);
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
                setToastrMsg(null);

                setIsLoading(false);
                setToastrError('An error occured');
            });
    };

    const alottAttendance = (formData, page, user, date) => {
        setIsLoading(true);
        axios
            .post(`${BASE_URL}/api/alott-attendance/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                const incomingData = res.data;
                if (incomingData.success) {
                    setIsLoading(false);
                    setToastrMsg(incomingData.msg);
                    setToastrError(null);
                    getAttendance({ page: page, user: user, date: date });
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                    setIsLoading(false);
                    setToastrError(incomingData.msg);
                    setToastrMsg(null);
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
                setToastrMsg(null);

                setIsLoading(false);
                setToastrError('An error occured');
            });
    };

    const changeStatus = (id, page, user, date) => {
        axios
            .get(`${BASE_URL}/api/change_status/${id}/`, {
                headers: {
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                const incomingData = res.data;
                if (incomingData.success) {
                    getAttendance({ page: page, user: user, date: date });
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
            });
    };

    const dashboard = () => {
        axios
            .get(`${BASE_URL}/api/dashboard/`, {
                headers: {
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                const incomingData = res.data;
                if (incomingData.success) {
                    setDashboardData(incomingData.data);
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
            });
    };

    const attendanceSummary = (year, month) => {
        axios
            .post(
                `${BASE_URL}/api/attendance-summary/`,
                {
                    year: year,
                    month: month,
                },
                {
                    headers: {
                        Authorization: `Token ${
                            JSON.parse(localStorage.getItem('userInfo'))?.token
                        }`,
                    },
                }
            )
            .then((res) => {
                const incomingData = res.data;
                if (incomingData.success) {
                    setAttendanceReport(incomingData.data);
                } else {
                    if (incomingData.msg === 'tokenDelete') {
                        logout();
                        return;
                    }
                }
            })
            .catch((err) => {
                const incomingError = err.response;

                if (
                    incomingError.status === 401 &&
                    incomingError.statusText === 'Unauthorized'
                ) {
                    logout();
                    return;
                }
            });
    };

    async function removeLocalStorage() {
        localStorage.removeItem('userInfo');
        setToken(null);
        setUserType(null);
        setLoggedOut(true);
        setUserInfo(null);
        setHasGSigned(null);
        setIsRegistered(false);
        setGoogleRegistered(false);
    }

    async function logout() {
        // localStorage.removeItem('userInfo');
        await removeLocalStorage();
    }

    // async function logOff() {
    //     await logout();
    // }

    return (
        <AuthContext.Provider
            value={{
                userInfo,
                contactsList,
                messages,
                cashFlow,
                userData,
                accountData,
                toastrMsg,
                toastrError,
                token,
                isRegistered,
                usersList,
                loggedOut,
                bulkDataMsg,
                bulkDataError,
                vendorAccounts,
                toastrEmailMsg,
                toastrEmailError,
                toastrOtpMsg,
                toastrOtpError,
                hasGSigned,
                gRegistered,
                companyData,
                taskTypes,
                taskList,
                cashFilterList,
                externalData,
                pages,
                attendanceList,
                profileData,
                companyLocations,
                userType,
                userProfileData,
                userLocations,
                dashboardData,
                attendanceReport,
                otpSent,
                cred,
                googleRegistered,
                userCreds,
                setGoogleRegistered,
                setUserCreds,
                getUserCreds,
                setIsRegistered,
                checkUserPassword,
                setOtpSent,
                checkGRegister,
                attendanceSummary,
                dashboard,
                setUserType,
                setHasGSigned,
                getUserAttendance,
                alottAttendance,
                getUserLocations,
                setToken,
                changeStatus,
                editUserLocations,
                getUserProfileInfo,
                markAttendance,
                getCompanyLocations,
                checkLocation,
                setCompanyLocations,
                editProfileInfo,
                getProfileInfo,
                getAttendance,
                editComapanyContactInfo,
                editCompanyInfo,
                getCompanyDetails,
                checkEmail,
                resetPassword,
                setPassword,
                setToastrEmailMsg,
                setToastrEmailError,
                setToastrOtpMsg,
                setToastrOtpError,
                sendOtp,
                setUserInfo,
                deleteUser,
                editUser,
                setBulkDataMsg,
                setBulkDataError,
                addBulkUser,
                addUser,
                getUsersList,
                setToastrMsg,
                setToastrError,
                verifyOtp,
                register,
                getInputDetails,
                login,
                logout,
            }}>
            {children}
        </AuthContext.Provider>
    );
};
