import React, { useState, useEffect, useContext } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Breadcrumb,
    BreadcrumbItem,
    Label,
    CustomInput,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
} from 'reactstrap';
// core components
import Select from 'react-select';
// import UserHeader from 'components/Headers/UserHeader.js';
import Header from 'components/Headers/Header';
import { BASE_URL } from 'utils/config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from 'utils/AuthContext';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useNavbarContext } from 'components/Navbars/NavbarContext';
import './overlay.css';

const UserProfile = () => {
    const {
        getUserProfileInfo,
        userProfileData,
        editProfileInfo,
        toastrMsg,
        toastrError,
        setToastrError,
        setToastrMsg,
        getCompanyLocations,
        companyLocations,
        setCompanyLocations,
        deleteUser,
        editUser,
        getUserCreds,
        userCreds,
    } = useContext(AuthContext);

    const { setPageTitle } = useNavbarContext();

    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [department, setDepartment] = useState('');
    const [designation, setDesignation] = useState('');
    const [empId, setEmpId] = useState('');
    const [locations, setLocations] = useState([]);
    const [locationsOption, setLocationsOption] = useState([]);
    const [selectedOption, setSelectedOption] = useState('anywhere');
    const [localOptions, setLocalOptions] = useState([]);
    const [isDelOpen, setIsDelOpen] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [type, setType] = useState('1');
    const [mode, setMode] = useState('1');
    const [officeStartTime, setOfficeStartTime] = useState('');
    const [officeEndTime, setOfficeEndTime] = useState('');

    const { id } = useParams();

    const navigate = useNavigate();

    const typeOptions = [
        { value: '1', label: 'Admin' },
        { value: '2', label: 'Non Admin' },
    ];

    const modeOptions = [
        { value: '1', label: 'On-site' },
        { value: '2', label: 'Off-site' },
    ];

    useEffect(() => {
        document.title = 'User Profile';
        setPageTitle('User Profile');
        setLocalOptions([]);
        getCompanyLocations();
        getUserCreds();
        getUserProfileInfo(id);
    }, []);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const delToggle = () => {
        setIsDelOpen(!isDelOpen);
    };

    useEffect(() => {
        setCompanyLocations(null);

        if (companyLocations) {
            // companyLocations?.locations.forEach((element) => {
            //     setLocalOptions((prev) => [
            //         ...prev,
            //         {
            //             value: element,
            //             label: `<b>${element.name}</b>${element.description}`,
            //             customLabel: (
            //                 <div>
            //                     <b>{element.name}</b> - {element.description}
            //                 </div>
            //             ),
            //         },
            //     ]);
            // });

            setLocalOptions(
                companyLocations?.locations.map((element) => {
                    return {
                        value: element,
                        label: `<b>${element.name}</b>${element.description}`,
                        customLabel: (
                            <div>
                                <b>{element.name}</b> - {element.description}
                            </div>
                        ),
                    };
                })
            );
        }
    }, [companyLocations]);

    useEffect(() => {}, [localOptions, locationsOption]);

    const formatOptionLabel = ({ label, customLabel }) => customLabel;

    useEffect(() => {
        setLocations([]);
        // setLocationsOption([]);
        // setSelectedOption('anywhere');
        setPhone(userProfileData?.phone);
        setDepartment(userProfileData?.department || '');
        setDesignation(userProfileData?.designation || '');
        setEmpId(userProfileData?.employee_id || '');
        setName(`${userProfileData?.first_name} ${userProfileData?.last_name}`);
        setEmail(userProfileData?.email);
        setPhone(userProfileData?.phone);
        setMode(userProfileData?.onsite ? '1' : '2');
        setType(userProfileData?.user_type);
        setOfficeStartTime(userProfileData?.office_start_time || '');
        setOfficeEndTime(userProfileData?.office_end_time || '');

        if (
            userProfileData?.locations &&
            userProfileData?.locations.length > 0
        ) {
            setLocations(userProfileData?.locations);
            setSelectedOption('locations');

            setLocationsOption(
                userProfileData?.locations.map((element) => {
                    return {
                        value: element,
                        label: `<b>${element.name}</b>${element.description}`,
                        customLabel: (
                            <div>
                                <b>{element.name}</b> - {element.description}
                            </div>
                        ),
                    };
                })
            );
            // userProfileData?.locations.forEach((element) => {

            //     setLocationsOption((prev) => [
            //         ...prev,
            //         {
            //             value: element,
            //             label: `<b>${element.name}</b>${element.description}`,
            //             customLabel: (
            //                 <div>
            //                     <b>{element.name}</b> - {element.description}
            //                 </div>
            //             ),
            //         },
            //     ]);
            // });
        } else {
            setSelectedOption('anywhere');
            setLocations([]);
        }
    }, [userProfileData]);

    const handleInfoChange = () => {
        const formData = new FormData();
        formData.append('phone', phone);
        formData.append('department', department);
        formData.append('designation', designation);
        formData.append('employeeId', empId);
        editProfileInfo(formData);
    };

    useEffect(() => {
        if (toastrMsg) {
            toast.success(toastrMsg);
            setToastrMsg(null);
            getUserProfileInfo(id);
            // navigate('/admin/users-list');
        } else if (toastrError) {
            toast.error(toastrError);
            setToastrError(null);
        }
        setLoading(false);
    }, [toastrMsg, toastrError, setToastrMsg, setToastrError]);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleLocationChange = (selectedOptions) => {
        const formattedLocations = selectedOptions.map((e) => e.value);
        setLocationsOption(
            formattedLocations.map((element) => {
                return {
                    value: element,
                    label: `<b>${element.name}</b>${element.description}`,
                    customLabel: (
                        <div>
                            <b>{element.name}</b> - {element.description}
                        </div>
                    ),
                };
            })
        );
        setLocations(formattedLocations);
    };

    const handleEdit = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('phone', phone);
        formData.append('id', id);
        formData.append('name', name);
        formData.append('department', department);
        formData.append('designation', designation);
        formData.append('employeeId', empId);
        formData.append('officeStartTime', officeStartTime);
        formData.append('officeEndTime', officeEndTime);
        formData.append('type', type);

        let locationsData;

        if (selectedOption === 'locations') {
            locationsData = JSON.stringify(locations);
            formData.append('locations', locationsData);
        }

        editUser(formData);
    };

    return (
        <>
            <Header />
            {/* Page content */}
            <ToastContainer />
            {userProfileData && !loading ? (
                <>
                    <Modal isOpen={isDelOpen} toggle={delToggle}>
                        <ModalHeader toggle={delToggle}>
                            Delete User
                        </ModalHeader>
                        <ModalBody>
                            <Form>
                                <h4>
                                    Are you sure you want to delete{' '}
                                    <span
                                        style={{
                                            color: 'red',
                                        }}>{`${userProfileData?.first_name} ${userProfileData?.last_name}`}</span>
                                </h4>
                                <ModalFooter>
                                    <Button
                                        color="danger"
                                        onClick={() => {
                                            deleteUser(id);
                                            delToggle();
                                            navigate('/admin/users-list');
                                        }}>
                                        Delete
                                    </Button>
                                    <Button
                                        color="secondary"
                                        onClick={() => delToggle()}>
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </Form>
                        </ModalBody>
                    </Modal>
                    <Container style={{ marginTop: '0.5rem' }} fluid>
                        <Row>
                            <Col className="" xl="4">
                                <Breadcrumb>
                                    <BreadcrumbItem>
                                        <Link to="/admin/users-list">
                                            Users List
                                        </Link>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        {`${userProfileData?.first_name} ${userProfileData?.last_name}`}
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                        </Row>
                        <Card className="bg-secondary shadow">
                            <Row>
                                <Col className="mb-3" xl="12">
                                    <CardBody>
                                        <Form>
                                            <h6 className="heading-small text-muted mb-4">
                                                User information
                                            </h6>
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-Name">
                                                                Name
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-Name"
                                                                placeholder="Name"
                                                                type="text"
                                                                value={name}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setName(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-email">
                                                                Email address
                                                            </label>
                                                            <Input
                                                                disabled={true}
                                                                className="form-control-alternative"
                                                                id="input-email"
                                                                placeholder="Email"
                                                                type="email"
                                                                value={email}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-first-name">
                                                                Phone
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-first-name"
                                                                placeholder="Phone"
                                                                type="number"
                                                                value={phone}
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    setPhone(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    );
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row></Row>
                                                <Row>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-department">
                                                                Department
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-department"
                                                                type="text"
                                                                placeholder="Department"
                                                                value={
                                                                    department
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    setDepartment(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    );
                                                                }}></Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-designation">
                                                                Designation
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-designation"
                                                                type="text"
                                                                placeholder="Designation"
                                                                value={
                                                                    designation
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    setDesignation(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    );
                                                                }}></Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-employee_id">
                                                                Employee ID
                                                            </label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-employee_id"
                                                                type="text"
                                                                placeholder="Employee ID"
                                                                value={empId}
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    setEmpId(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    );
                                                                }}></Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="4">
                                                        <FormGroup>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-user-type">
                                                                User Type
                                                            </label>
                                                            <Select
                                                                name="user_type"
                                                                size="sm"
                                                                options={
                                                                    typeOptions
                                                                }
                                                                className="form-control-alternative"
                                                                classNamePrefix="select"
                                                                value={typeOptions.find(
                                                                    (option) =>
                                                                        option.value ===
                                                                        type
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setType(
                                                                        e.value
                                                                    );
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                {/* <Row>
                                            <Col lg="12">
                                                <Button
                                                    className="float-right"
                                                    color="primary"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        // handleBasicInfoChange();
                                                    }}
                                                    size="sm">
                                                    Save Changes
                                                </Button>
                                            </Col>
                                        </Row> */}
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Col>

                                <Col xl="12">
                                    <CardBody>
                                        <Form>
                                            <h6 className="heading-small text-muted mb-4">
                                                Office Timings
                                            </h6>
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <label
                                                                for="officeStartTime"
                                                                className="form-control-label">
                                                                Office Start
                                                                Time
                                                            </label>
                                                            <Input
                                                                type="time"
                                                                name="officeStartTime"
                                                                id="officeStartTime"
                                                                placeholder="Start Time"
                                                                className="form-control-alternative"
                                                                value={
                                                                    officeStartTime
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setOfficeStartTime(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="3">
                                                        <FormGroup>
                                                            <label
                                                                for="officeEndTime"
                                                                className="form-control-label">
                                                                Office End Time
                                                            </label>
                                                            <Input
                                                                type="time"
                                                                name="officeEndTime"
                                                                id="officeEndTime"
                                                                className="form-control-alternative"
                                                                placeholder="End Time"
                                                                value={
                                                                    officeEndTime
                                                                }
                                                                onChange={(e) =>
                                                                    setOfficeEndTime(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Col>

                                <Col xl="12">
                                    <CardBody>
                                        <Form>
                                            <h6 className="heading-small text-muted mb-4">
                                                Mark Attendance From
                                            </h6>
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup check>
                                                            <label check>
                                                                <CustomInput
                                                                    type="radio"
                                                                    id="info"
                                                                    name="selectOption"
                                                                    value="anywhere"
                                                                    checked={
                                                                        selectedOption ===
                                                                        'anywhere'
                                                                    }
                                                                    onChange={
                                                                        handleOptionChange
                                                                    }
                                                                    label="Anywhere"
                                                                />
                                                            </label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup check>
                                                            <label check>
                                                                <CustomInput
                                                                    type="radio"
                                                                    id="locations"
                                                                    name="selectOption"
                                                                    value="locations"
                                                                    checked={
                                                                        selectedOption ===
                                                                        'locations'
                                                                    }
                                                                    onChange={
                                                                        handleOptionChange
                                                                    }
                                                                    label="Selected Locations"
                                                                />
                                                            </label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                {selectedOption ===
                                                    'locations' && (
                                                    <div>
                                                        <Row>
                                                            <Col lg="12">
                                                                <FormGroup>
                                                                    <label
                                                                        className="form-control-label"
                                                                        htmlFor="input-locations">
                                                                        Locations
                                                                    </label>
                                                                    <Select
                                                                        menuPlacement="top"
                                                                        isMulti
                                                                        name="locations"
                                                                        options={
                                                                            localOptions
                                                                        }
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                        onChange={
                                                                            handleLocationChange
                                                                        }
                                                                        formatOptionLabel={
                                                                            formatOptionLabel
                                                                        }
                                                                        defaultValue={
                                                                            locationsOption
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )}
                                                {/* <Row>
                                            <Col lg="12">
                                                <Button
                                                    className="float-right"
                                                    color="primary"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                    
                                                        // Handle Save Changes logic here
                                                    }}
                                                    size="sm">
                                                    Save Changes
                                                </Button>
                                            </Col>
                                        </Row> */}
                                            </div>
                                        </Form>
                                    </CardBody>
                                </Col>

                                <Col lg="12" className="my-2 me-4">
                                    <Row>
                                        <CardBody>
                                            <Col lg="12">
                                                <Button
                                                    className="float-right mx-3"
                                                    color="primary"
                                                    onClick={(e) => {
                                                        e.preventDefault();

                                                        handleEdit();
                                                        // Handle Save Changes logic here
                                                    }}
                                                    size="sm">
                                                    Save Changes
                                                </Button>
                                                {userCreds &&
                                                    userCreds.userId !== id && (
                                                        <Button
                                                            className="float-right"
                                                            color="danger"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                delToggle();
                                                            }}
                                                            size="sm">
                                                            Delete User
                                                        </Button>
                                                    )}
                                            </Col>
                                        </CardBody>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </>
            ) : (
                <div className="overlay">
                    <Spinner
                        style={{ width: '3rem', height: '3rem' }}
                        color="primary"
                    />
                </div>
            )}
        </>
    );
};

export default UserProfile;
