/*!

=========================================================
* Argon Dashboard React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Spinner,
} from 'reactstrap';
import logo from '../../assets/img/home/logo.png';
// import GoogleLogin from 'react-google-login';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { AuthContext } from 'utils/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';

const Login = () => {
    const {
        login,
        token,
        userType,
        checkEmail,
        hasGSigned,
        toastrMsg,
        setToastrMsg,
        toastrError,
        setToastrError,
    } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const navigate = useNavigate();

    function handleLogin(username, password) {
        setDisabled(true);
        setIsLoading(true);
        if (username !== '' && password !== '') {
            if (emailValidator(username)) {
                login(username, password);
            } else {
                toast.error('Enter a valid email');
                setIsLoading(false);
            }
        }
    }

    useEffect(() => {
        document.title = 'Login';
    }, []);

    useEffect(() => {
        if (toastrMsg) {
            toast.success(toastrMsg);
            setToastrMsg(null);
        } else if (toastrError) {
            toast.error(toastrError);
            setToastrError(null);
        }
        setIsLoading(false);
        setPassword('');
    }, [toastrMsg, toastrError, setToastrMsg, setToastrError]);

    const emailValidator = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const handleOnSuccess = (response) => {
        const idToken = response?.credential;
        if (idToken) {
            const decodeToken = jwtDecode(idToken);
            const email = decodeToken?.email;
            checkEmail(email);
        }
        // const returnedEmail = response?.profileObj?.email;

        // checkEmail(returnedEmail);
    };

    const checkComplete = () => {
        if (username !== '' && password !== '') {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    useEffect(() => {
        checkComplete();
    }, [password, username]);

    const handleOnFailure = (response) => {
        toast.error('Could not sign in');
    };

    useEffect(() => {
        if (token && userType) {
            if (userType === '1') {
                navigate('/admin/dashboard');
            } else if (userType === '2') {
                navigate('/mark-attendance');
            }
            setUsername('');
            setPassword('');
        }
    }, [token, userType]);
    return (
        <>
            <ToastContainer />
            <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent pb-5">
                        <div
                            style={{ width: '100%', alignSelf: 'center' }}
                            className="text-center">
                            <img src={logo} style={{ width: '200px' }} />
                        </div>
                        <br />
                        <br />
                        <div className="text-muted text-center mt-2 ">
                            <div className="d-flex justify-content-center">
                                <GoogleOAuthProvider clientId="426181317198-llu2ionrgv0kohv2in93tm73vjsbb5ec.apps.googleusercontent.com">
                                    <GoogleLogin
                                        // href=""
                                        theme="filled_blue"
                                        className="btn ms-1 my-4 font-weight-bold"
                                        onSuccess={handleOnSuccess}
                                        onError={handleOnFailure}
                                        // useOneTap
                                        autoSelect={false}
                                    />
                                </GoogleOAuthProvider>
                                {/* <GoogleLogin
                                    clientId="426181317198-llu2ionrgv0kohv2in93tm73vjsbb5ec.apps.googleusercontent.com"
                                    onSuccess={handleOnSuccess}
                                    onFailure={handleOnFailure}
                                    isLoggedIn={true}
                                /> */}
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            <small>Or sign in with credentials</small>
                        </div>
                        <Form role="form">
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-email-83" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Email"
                                        type="email"
                                        autoComplete="new-email"
                                        value={username}
                                        onChange={(event) =>
                                            setUsername(event.target.value)
                                        }
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Password"
                                        type="password"
                                        autoComplete="new-password"
                                        value={password}
                                        onChange={(event) =>
                                            setPassword(event.target.value)
                                        }
                                    />
                                </InputGroup>
                            </FormGroup>
                            {/* <div className="custom-control custom-control-alternative custom-checkbox">
                                <input
                                    className="custom-control-input"
                                    id=" customCheckLogin"
                                    type="checkbox"
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor=" customCheckLogin">
                                    <span className="text-muted">
                                        Remember me
                                    </span>
                                </label>
                            </div> */}
                            <div className="text-center">
                                <Button
                                    disabled={disabled}
                                    className="my-4"
                                    color="primary"
                                    onClick={() =>
                                        handleLogin(username, password)
                                    }>
                                    {!isLoading ? (
                                        'Sign In'
                                    ) : (
                                        <>
                                            <Spinner size="sm" />
                                        </>
                                    )}
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
                <Row className="mt-3">
                    <Col xs="6">
                        <Link className="text-light" to="/auth/set-password">
                            <small>Forgot password?</small>
                        </Link>
                    </Col>
                    <Col className="text-right" xs="6">
                        <Link className="text-light" to="/auth/register">
                            <small>Create new account</small>
                        </Link>
                    </Col>
                </Row>
            </Col>
        </>
    );
};

export default Login;
