import React from 'react';
import { useLocation, Route, Routes, Navigate } from 'react-router-dom';
// reactstrap components
import { Container } from 'reactstrap';
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import AdminFooter from 'components/Footers/AdminFooter.js';
// import NonAdminSidebar from 'components/Sidebar/NonAdminSidebar.js';

import routes from 'routes/routes.js';
import { AuthenticatedRoute } from 'routes/protectedRoutes';
import { NavbarProvider } from 'components/Navbars/NavbarContext';

const NonAdmin = (props) => {
    const mainContent = React.useRef(null);
    const location = useLocation();

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;
    }, [location]);

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === '/nonadmin') {
                if (prop.children?.length > 0) {
                    return getRoutes(prop.children);
                }
                return (
                    <Route
                        path={prop.path}
                        element={
                            <AuthenticatedRoute>
                                {prop.component}
                            </AuthenticatedRoute>
                        }
                        key={key}
                        exact
                    />
                );
            } else {
                return null;
            }
        });
    };

    const getBrandText = (path) => {
        for (let i = 0; i < routes.length; i++) {
            if (
                props?.location?.pathname.indexOf(
                    routes[i].layout + routes[i].path
                ) !== -1
            ) {
                return routes[i].name;
            }
        }
        return 'Brand';
    };

    return (
        <>
            <NavbarProvider>
                <div className="main-content" ref={mainContent}>
                    <AdminNavbar
                        {...props}
                        brandText={getBrandText(props?.location?.pathname)}
                    />
                    <Routes>
                        {getRoutes(routes)}
                        <Route
                            path="*"
                            element={<Navigate to="/auth/login" replace />}
                        />
                    </Routes>
                </div>
            </NavbarProvider>
        </>
    );
};

export default NonAdmin;
