import React, { useEffect, useState, useContext } from 'react';
import { Calendar } from 'react-date-range';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { setMonth, setYear } from 'date-fns';
import './overlay.css';
import {
    format,
    addDays,
    startOfMonth,
    endOfMonth,
    isSameMonth,
    isAfter,
} from 'date-fns';
import './date-picker-popper.css';
import {
    Button,
    Card,
    CardHeader,
    CardImg,
    Form,
    FormGroup,
    Input,
    Table,
    Container,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Spinner,
} from 'reactstrap';
import Header from 'components/Headers/Header.js';
import { AuthContext } from 'utils/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavbarContext } from 'components/Navbars/NavbarContext';
import Papa from 'papaparse';

export default function MonthyReport() {
    const { attendanceSummary, attendanceReport } = useContext(AuthContext);
    const { setPageTitle } = useNavbarContext();
    const [selectedMonth, setSelectedMonth] = useState(new Date());
    const [date, setDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [daysInMonth, setDaysInMonth] = useState(getDaysInMonth(date));
    const [isFutureMonth, setIsFutureMonth] = useState();
    const currentDate = new Date();

    useEffect(() => {
        setLoading(true);
        setPageTitle('Attendance Report');
        document.title = 'Attendance Report';
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        attendanceSummary(year.toString(), month.toString());
        setLoading(false);
    }, []);

    useEffect(() => {
        if (attendanceReport) {
            setDaysInMonth(getDaysInMonth(date));
            setIsFutureMonth(isAfter(date, currentDate));
        }
    }, [attendanceReport]);
    function getDaysInMonth(date) {
        const start = startOfMonth(date);
        const end = endOfMonth(date);
        const days = [];
        for (let day = start; day <= end; day = addDays(day, 1)) {
            days.push(day);
        }
        return days;
    }

    // const daysInMonth = getDaysInMonth(date);

    // Check if the selected month is greater than the current month
    // const isFutureMonth = isAfter(date, currentDate);

    // Limit columns up to the current date for the current month
    const limitedDaysInMonth = daysInMonth.filter(
        (day) => !isSameMonth(day, currentDate) || day <= currentDate
    );

    function handlFilter() {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        attendanceSummary(year.toString(), month.toString());
    }

    const handleDateChange = (selectedDate) => {
        setDate(selectedDate);
        // Perform any other actions on date change if needed
    };

    const handleReset = () => {
        const today = new Date();
        setDate(today);
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        attendanceSummary(year.toString(), month.toString());
    };

    const downloadCSV = () => {
        const data = Object.keys(attendanceReport).map((employeeId) => {
            const attendance = attendanceReport[employeeId];
            const record = {
                employeeId: employeeId,
                user: attendance.user,
                present: attendance.present,
                on_time: attendance.on_time,
                late: attendance.late,
                absent: attendance.absent,
                ...attendance.days,
            };
            return record;
        });

        const csv = Papa.unparse(data);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'attendance_report.csv';
        link.click();
    };

    return (
        <>
            <Header />
            <Container className="mt-4" fluid>
                <Row>
                    <ToastContainer />
                    {attendanceReport && !loading ? (
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card
                                className="shadow"
                                style={{ height: '42rem' }}>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center justify-content-between">
                                        <Form className="d-flex flex-row  align-items-center w-25">
                                            <FormGroup
                                                className="mr-4"
                                                style={{ flex: 3 }}>
                                                <label
                                                    style={{ display: 'block' }}
                                                    className="form-control-label"
                                                    htmlFor="filter-status">
                                                    Month
                                                </label>
                                                <DatePicker
                                                    className="form-control-alternative"
                                                    selected={date}
                                                    onChange={handleDateChange}
                                                    dateFormat="MMMM yyyy"
                                                    showMonthYearPicker
                                                    showFullMonthYearPicker
                                                />
                                            </FormGroup>

                                            <Button
                                                className="form-control-alternative mt-2 ml--1"
                                                color="primary"
                                                size="sm"
                                                onClick={(e) => {
                                                    e.preventDefault();

                                                    // getAttendance({
                                                    //     page: page + 1,
                                                    //     user: filterUser,
                                                    //     date: convertDateFormat(
                                                    //         filterDate
                                                    //     ),
                                                    // });
                                                    handlFilter();
                                                    // setPage(0);
                                                }}>
                                                Filter
                                            </Button>

                                            <Button
                                                className="form-control-alternative mt-2"
                                                color="primary"
                                                size="sm"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleReset();
                                                    // setFilterUser('');
                                                    // setFilterDate('');
                                                    // getAttendance();
                                                }}>
                                                Reset
                                            </Button>
                                        </Form>
                                        <Row>
                                            {/* <Col></Col> */}
                                            <Col>
                                                <Table bordered size="sm">
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    fontWeight:
                                                                        'bold',
                                                                    backgroundColor:
                                                                        '#1CAD6F',
                                                                }}>
                                                                On-Time
                                                            </td>
                                                            <td
                                                                style={{
                                                                    fontWeight:
                                                                        'bold',
                                                                    backgroundColor:
                                                                        '#FFCD56',
                                                                }}>
                                                                Late
                                                            </td>
                                                            <td
                                                                style={{
                                                                    fontWeight:
                                                                        'bold',
                                                                    backgroundColor:
                                                                        '#FF6484',
                                                                }}>
                                                                Absent
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>
                                            {/* <Col></Col> */}
                                        </Row>
                                        <Form>
                                            <Col>
                                                <Button
                                                    outline
                                                    color="info"
                                                    size="sm"
                                                    className="float-right"
                                                    onClick={downloadCSV}>
                                                    Download
                                                </Button>
                                            </Col>
                                        </Form>
                                    </Row>
                                    {/* <Row>
                                        <Col>
                                            <Button
                                                outline
                                                color="info"
                                                size="sm"
                                                className="float-right"
                                                onClick={downloadCSV}>
                                                Download
                                            </Button>
                                        </Col>
                                    </Row> */}
                                </CardHeader>

                                <div
                                    style={{
                                        height: '100%',
                                        overflowY: 'auto',
                                    }}>
                                    <Table
                                        bordered
                                        // className="align-items-center"
                                        style={{}}>
                                        <thead
                                            className="thead-light"
                                            style={{
                                                position: 'sticky',
                                                top: '0',
                                                zIndex: '2',
                                            }}>
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="text-dark text-bold"
                                                    style={{
                                                        position: 'sticky',
                                                        left: '0',
                                                        zIndex: '2',
                                                    }}>
                                                    User
                                                </th>

                                                <th
                                                    scope="col"
                                                    className="text-dark text-bold">
                                                    Present
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-dark text-bold">
                                                    On Time
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-dark text-bold">
                                                    Late
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-dark text-bold">
                                                    Absent
                                                </th>
                                                {daysInMonth &&
                                                    daysInMonth.map((day) => (
                                                        <th
                                                            scope="col"
                                                            key={day}
                                                            className="text-dark text-bold">
                                                            {format(
                                                                day,
                                                                'dd MMM'
                                                            )}
                                                        </th>
                                                    ))}
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {isFutureMonth ? (
                                                <tr>
                                                    <td
                                                        colSpan={
                                                            6 +
                                                            limitedDaysInMonth.length
                                                        }
                                                        className="text-center">
                                                        No data available
                                                    </td>
                                                </tr>
                                            ) : (
                                                attendanceReport &&
                                                Object.keys(
                                                    attendanceReport
                                                ).map((employeeId) => (
                                                    <tr key={employeeId}>
                                                        <td
                                                            scope="row"
                                                            className="font-weight-bold sticky-column"
                                                            style={{
                                                                position:
                                                                    'sticky',
                                                                left: '0',
                                                                zIndex: '1',
                                                                backgroundColor:
                                                                    'white',
                                                            }}>
                                                            {
                                                                attendanceReport[
                                                                    employeeId
                                                                ].user
                                                            }
                                                            {/* <br></br>
                                                            <small className="font-weight-bold">
                                                                {employeeId}
                                                            </small> */}
                                                        </td>

                                                        <td scope="row">
                                                            {
                                                                attendanceReport[
                                                                    employeeId
                                                                ].present
                                                            }
                                                        </td>
                                                        <td scope="row">
                                                            {
                                                                attendanceReport[
                                                                    employeeId
                                                                ].on_time
                                                            }
                                                        </td>
                                                        <td scope="row">
                                                            {
                                                                attendanceReport[
                                                                    employeeId
                                                                ].late
                                                            }
                                                        </td>
                                                        <td scope="row">
                                                            {
                                                                attendanceReport[
                                                                    employeeId
                                                                ].absent
                                                            }
                                                        </td>
                                                        {limitedDaysInMonth.map(
                                                            (day) => {
                                                                const status =
                                                                    attendanceReport[
                                                                        employeeId
                                                                    ].days[
                                                                        format(
                                                                            day,
                                                                            'yyyy-MM-dd'
                                                                        )
                                                                    ];
                                                                let backgroundColor =
                                                                    '';
                                                                if (
                                                                    status ===
                                                                    'on_time'
                                                                )
                                                                    backgroundColor =
                                                                        'green';
                                                                else if (
                                                                    status ===
                                                                    'late'
                                                                )
                                                                    backgroundColor =
                                                                        'orange';
                                                                else
                                                                    backgroundColor =
                                                                        'red';
                                                                return (
                                                                    <td
                                                                        scope="row"
                                                                        key={
                                                                            day
                                                                        }
                                                                        style={{
                                                                            backgroundColor,
                                                                        }}></td>
                                                                );
                                                            }
                                                        )}
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card>
                        </Col>
                    ) : (
                        <div className="overlay">
                            <Spinner
                                style={{ width: '3rem', height: '3rem' }}
                                color="primary"
                            />
                        </div>
                    )}
                </Row>
            </Container>
        </>
    );
}
