import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Form,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    InputGroup,
    Navbar,
    Nav,
    Container,
    Media,
} from 'reactstrap';
import { AuthContext } from 'utils/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useNavbarContext } from './NavbarContext';
import UserProfileImage from '../../assets/img/theme/user-profile.png';

const AdminNavbar = (props) => {
    const { pageTitle } = useNavbarContext();
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const {
        logout,
        loggedOut,
        setUserInfo,
        setToken,
        setHasGSigned,
        setUserType,
        setIsRegistered,
        getUserCreds,
        userCreds,
        setGoogleRegistered,
    } = useContext(AuthContext);
    const navigate = useNavigate();
    const handleLogout = () => {
        logout();
        // setUserInfo(null);
        // setHasGSigned(null);
        // setUserType(null);
        // setIsRegistered(false);
        // setToken(null);
        // setGoogleRegistered(false);

        // navigate("/auth/login", { replace: true });
        // return;
    };

    useEffect(() => {
        getUserCreds();
    }, []);

    useEffect(() => {
        if (loggedOut) {
            // navigate(0);
            navigate('/auth/login');
            // navigate(0);
        }
    }, [loggedOut]);

    return (
        <>
            <Navbar
                className="navbar-top navbar-dark sticky-top"
                expand="md"
                id="navbar-main"
                style={{ zIndex: '300' }}>
                <Container fluid>
                    <div className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">
                        {pageTitle}
                    </div>

                    <Nav className="align-items-center d-none d-md-flex" navbar>
                        <UncontrolledDropdown nav>
                            <DropdownToggle className="pr-0" nav>
                                <Media className="align-items-center">
                                    <span className="avatar avatar-sm rounded-circle">
                                        {userInfo && userCreds ? (
                                            <img
                                                alt="..."
                                                src={`https://ui-avatars.com/api/?name=${userCreds?.name}&background=BBDEFB&bold=true&rounded=true`}
                                            />
                                        ) : (
                                            <img
                                                src={UserProfileImage}
                                                className="p-1"></img>
                                        )}
                                    </span>
                                </Media>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                    className="noti-title"
                                    header
                                    tag="div">
                                    <h5 className="text-overflow m-0">
                                        Welcome, {userCreds?.name.split(' ')[0]}
                                        !
                                    </h5>
                                </DropdownItem>

                                {userInfo?.token && (
                                    <>
                                        <DropdownItem
                                            to={
                                                userInfo?.user_type &&
                                                userInfo?.user_type === '1'
                                                    ? '/admin/user-profile'
                                                    : '/user-profile'
                                            }
                                            tag={Link}>
                                            <i className="ni ni-single-02" />
                                            <span>My profile</span>
                                        </DropdownItem>
                                        <DropdownItem
                                            to={
                                                userInfo?.user_type &&
                                                userInfo?.user_type === '1'
                                                    ? '/admin/mark-attendance'
                                                    : '/mark-attendance'
                                            }
                                            tag={Link}>
                                            <i className="ni ni-calendar-grid-58" />
                                            <span>Mark My Attendance</span>
                                        </DropdownItem>
                                        {userInfo?.user_type &&
                                            userInfo?.user_type === '2' && (
                                                <DropdownItem
                                                    to="/user-attendance"
                                                    tag={Link}>
                                                    <i className="ni ni-bullet-list-67" />
                                                    <span>
                                                        My Attendance Log
                                                    </span>
                                                </DropdownItem>
                                            )}
                                        <DropdownItem
                                            to={
                                                userInfo?.user_type &&
                                                userInfo?.user_type === '1'
                                                    ? '/admin/change-password'
                                                    : '/change-password'
                                            }
                                            tag={Link}>
                                            <i className="ni ni-lock-circle-open" />
                                            <span>Change Password</span>
                                        </DropdownItem>
                                    </>
                                )}

                                <DropdownItem divider />
                                <DropdownItem
                                    onClick={(e) => {
                                        // e.preventDefault();
                                        handleLogout();
                                    }}>
                                    <i className="ni ni-user-run" />
                                    <span>Logout</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Container>
            </Navbar>
        </>
    );
};

export default AdminNavbar;
