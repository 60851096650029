import { useContext, useEffect, useState, useRef, useCallback } from 'react';
// reactstrap components
import { Button, Card, Container, Row, Col, Spinner } from 'reactstrap';

// core components

import { Calendar } from 'react-date-range';
import Header from 'components/Headers/Header.js';
import { AuthContext } from 'utils/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TiPencil, TiTrash, TiPlus } from 'react-icons/ti';
import { BASE_URL } from 'utils/config';
import ReactPaginate from 'react-paginate';
import Webcam from 'react-webcam';
import CheckGeoCodes from 'utils/helperFunctions';
import { useNavbarContext } from 'components/Navbars/NavbarContext';

const MarkAttendance = (props) => {
    const {
        getAttendance,
        attendanceList,

        getUsersList,
        toastrMsg,
        toastrError,
        setToastrError,
        setToastrMsg,
        markAttendance,
        userLocations,
        getUserLocations,
    } = useContext(AuthContext);

    const { setPageTitle } = useNavbarContext();

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    const webcamRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(null);
    const [location, setLocation] = useState();
    const [isLoading, setIsLoading] = useState();
    const [exempt, setExempt] = useState(false);
    const [permissionsGranted, setPermissionsGranted] = useState({
        camera: false,
        location: false,
    });

    // const [inPremises, setInPremises] = useState(false);

    const google = window.google;

    useEffect(() => {
        document.title = 'Mark Attendance';
        setPageTitle('Mark My Attendance');
        getUserLocations();
        requestPermissions();
    }, []);

    useEffect(() => {
        if (userLocations && userLocations.length > 0) {
            setExempt(false);
        } else {
            setExempt(true);
        }
    }, [userLocations]);

    useEffect(() => {
        if (toastrMsg) {
            toast.success(toastrMsg);
            setToastrMsg(null);
        } else if (toastrError) {
            toast.error(toastrError);
            setToastrError(null);
        }
        setIsLoading(false);
    }, [toastrMsg, toastrError, setToastrMsg, setToastrError]);

    const requestPermissions = async () => {
        try {
            // Request location permissions
            await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setLocation(position.coords);
                        setPermissionsGranted((prev) => ({
                            ...prev,
                            location: true,
                        }));
                        resolve();
                    },
                    (error) => {
                        toast.error('Location permission denied');
                        reject(error);
                    }
                );
            });

            // Check camera permissions using Webcam
            const webcamStream = await navigator.mediaDevices.getUserMedia({
                video: true,
            });
            if (webcamStream) {
                setPermissionsGranted((prev) => ({
                    ...prev,
                    camera: true,
                }));
                webcamStream.getTracks().forEach((track) => track.stop());
            }
        } catch (error) {
            toast.error('Camera permission denied');
        }
    };

    function convertDateFormat(inputDate) {
        if (inputDate) {
            const dateParts = inputDate.split('/');

            const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;

            return formattedDate;
        }
        return inputDate;
    }

    function formatDateToCustomFormat(isoDate) {
        const options = {
            day: 'numeric',
            month: 'long',
            hour: '2-digit',
            year: 'numeric',
            minute: '2-digit',
            second: '2-digit',
        };

        const date = new Date(isoDate);
        const formattedDate = date.toLocaleString('en-US', options);

        return formattedDate;
    }

    const blobToDataURI = async (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const dataURI = reader.result;
                resolve(dataURI);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(blob);
        });
    };

    function calculateSphericalDistance(y1, y2, x1, x2) {
        const R = 6321;
        const dLat = ((x2 - x1) * Math.PI) / 180;
        const dLon = ((y2 - y1) * Math.PI) / 180;
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos((x1 * Math.PI) / 180) *
                Math.cos((x2 * Math.PI) / 180) *
                Math.sin(dLon / 2) *
                Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c * 1000;
        return distance;
    }

    const reduceImageQuality = (imageBlob, quality = 0.7) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(imageBlob);
            reader.onloadend = () => {
                const base64data = reader.result;
                const img = new Image();
                img.src = base64data;

                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0);

                    canvas.toBlob(
                        (blob) => {
                            resolve(blob);
                        },
                        'image/jpeg', // You can use 'image/png' if you prefer
                        quality
                    );
                };
            };
        });
    };

    const cameraClick = async () => {
        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot();
            const blobData = await fetch(imageSrc).then((res) => res.blob());
            const currentDate = new Date();
            const isoString = currentDate.toISOString();
            const reducedBlobData = await reduceImageQuality(blobData, 0.5);
            const file = new File(
                [reducedBlobData],
                `${userInfo?.name}_${isoString}.png`,
                {
                    type: blobData.type,
                }
            );
            return file;
        } else {
            // toast.error('Camera not ready');
            return null;
        }
        // const imageSrc = webcamRef.current.getScreenshot();
        // const blobData = await fetch(imageSrc).then((res) => res.blob());
        // const currentDate = new Date();
        // const isoString = currentDate.toISOString();
        // const reducedBlobData = await reduceImageQuality(blobData, 0.5);
        // const file = new File(
        //     [reducedBlobData],
        //     `${userInfo?.name}_${isoString}.png`,
        //     {
        //         type: blobData.type,
        //     }
        // );
        // return file;
    };
    const handleClick = async () => {
        setIsLoading(true);
        const result = await CheckGeoCodes();

        const formData = new FormData();
        if (userLocations?.locations.length === 0) {
            const file = await cameraClick();
            formData.append('id', userInfo?.userId);
            formData.append('imgData', file);
            // formData.append('location', []);
            formData.append('blob', true);
            formData.append(
                'unknownLocation',
                JSON.stringify({ lat: result.lat, lng: result.lng })
            );
            markAttendance(formData);
            return;
        }

        let inPremises = false;

        for (const loc of userLocations?.locations || []) {
            const distance = calculateSphericalDistance(
                loc?.geocodes?.lat,
                result.lat,
                loc?.geocodes.lng,
                result.lng
            );

            if (distance <= 100) {
                inPremises = true;
                // setLocation();
                const file = await cameraClick();
                formData.append('id', userInfo?.userId);
                formData.append('imgData', file);
                formData.append('location', JSON.stringify(loc));
                formData.append('blob', true);
                markAttendance(formData);
                setIsLoading(false);
                return;
            }
        }

        toast.error("You're not in office premises");

        setIsLoading(false);

        // if (exempt) {
        // }
    };
    return (
        <>
            <ToastContainer />
            <Header />
            <Container className="mt-4" fluid>
                {userLocations ? (
                    <Row>
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card
                                className="shadow"
                                style={{ height: '42rem' }}>
                                <div
                                    className="container d-flex justify-content-center align-items-center"
                                    // style={{ height: '100vw' }}
                                >
                                    {permissionsGranted.camera && (
                                        <Webcam
                                            height={'80%'}
                                            width={'60%'}
                                            ref={webcamRef}
                                        />
                                    )}
                                </div>
                                <div className="container mb-5 text-center">
                                    <Button
                                        disabled={isLoading}
                                        hidden={
                                            !permissionsGranted.camera ||
                                            !permissionsGranted.location
                                        }
                                        className=""
                                        color="primary"
                                        type="button"
                                        onClick={() => handleClick()}>
                                        {!isLoading ? (
                                            `Mark My Attendance`
                                        ) : (
                                            <Spinner size="sm" />
                                        )}
                                    </Button>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <div className="overlay">
                        <Spinner
                            style={{ width: '3rem', height: '3rem' }}
                            color="primary"
                        />
                    </div>
                )}
            </Container>
        </>
    );
};

export default MarkAttendance;
