import React, { useState, useEffect, useContext } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    CustomInput,
    Spinner,
} from 'reactstrap';

import UserHeader from 'components/Headers/UserHeader.js';
import Header from 'components/Headers/Header';
import { BASE_URL } from 'utils/config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from 'utils/AuthContext';
import Select from 'react-select';
import { useNavbarContext } from 'components/Navbars/NavbarContext';

const Profile = () => {
    const {
        getProfileInfo,
        editProfileInfo,
        profileData,
        resetPassword,
        toastrMsg,
        toastrError,
        setToastrError,
        setToastrMsg,
        getCompanyLocations,
        companyLocations,
        setCompanyLocations,
        editUser,
    } = useContext(AuthContext);
    const { setPageTitle } = useNavbarContext();

    const [phone, setPhone] = useState(null);
    const [department, setDepartment] = useState('');
    const [designation, setDesignation] = useState('');
    const [empId, setEmpId] = useState('');
    const [officeStartTime, setOfficeStartTime] = useState('');
    const [officeEndTime, setOfficeEndTime] = useState('');
    const [mode, setMode] = useState('1');
    const [selectedOption, setSelectedOption] = useState('anywhere');
    const [locations, setLocations] = useState([]);
    const [name, setName] = useState('');
    const [id, setId] = useState('');

    useEffect(() => {
        document.title = 'My Profile';
        setPageTitle('My Profile');
        getProfileInfo();
        getCompanyLocations();
    }, []);

    useEffect(() => {
        setId(profileData?.id);
        setName(`${profileData?.first_name} ${profileData?.last_name}`);
        setPhone(profileData?.phone);
        setDepartment(profileData?.department);
        setDesignation(profileData?.designation);
        setEmpId(profileData?.employee_id);
        setOfficeEndTime(profileData?.office_end_time || '');
        setOfficeStartTime(profileData?.office_start_time || '');

        if (profileData?.locations && profileData?.locations.length > 0) {
            setLocations(profileData?.locations);
            setSelectedOption('locations');
        }
    }, [profileData]);

    const handleInfoChange = () => {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('department', department);
        formData.append('designation', designation);
        formData.append('employeeId', empId);
        formData.append('officeStartTime', officeStartTime);
        formData.append('officeEndTime', officeEndTime);

        formData.append('mode', mode);

        let locationsData;

        if (selectedOption === 'locations') {
            locationsData = JSON.stringify(locations);
        }

        formData.append('locations', locationsData);
        editUser(formData);
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleLocationChange = (selectedOptions) => {
        const formattedLocations = selectedOptions.map((e) => e.value);
        setLocations(formattedLocations);
    };

    const formatOptionLabel = ({ label, customLabel }) => customLabel;

    return (
        <>
            <Header />
            <ToastContainer />
            <Container style={{ marginTop: '4rem' }} fluid>
                {profileData ? (
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardBody>
                                    <Form>
                                        <div className="content">
                                            <Card className="mb-4">
                                                <CardHeader>
                                                    <h3 className="mb-0">
                                                        User Information
                                                    </h3>
                                                </CardHeader>
                                                <CardBody>
                                                    <div className="pl-lg-4">
                                                        <Row>
                                                            <Col lg="4">
                                                                <div className="info-group mb-2">
                                                                    <small>
                                                                        Name
                                                                    </small>
                                                                    <br></br>
                                                                    <strong>
                                                                        {name}
                                                                    </strong>
                                                                </div>
                                                            </Col>
                                                            <Col lg="4">
                                                                <div className="info-group mb-2">
                                                                    <small>
                                                                        Email
                                                                        Address:
                                                                    </small>
                                                                    <br></br>
                                                                    <strong>
                                                                        {
                                                                            JSON.parse(
                                                                                localStorage.getItem(
                                                                                    'userInfo'
                                                                                )
                                                                            )
                                                                                ?.email
                                                                        }
                                                                    </strong>
                                                                </div>
                                                            </Col>
                                                            <Col lg="4">
                                                                <div className="info-group mb-2">
                                                                    <small>
                                                                        Phone:
                                                                    </small>
                                                                    <br></br>
                                                                    <strong>
                                                                        {phone}
                                                                    </strong>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="4">
                                                                <div className="info-group mb-2">
                                                                    <small>
                                                                        Department:
                                                                    </small>
                                                                    <br></br>
                                                                    <strong>
                                                                        {
                                                                            department
                                                                        }
                                                                    </strong>
                                                                </div>
                                                            </Col>
                                                            <Col lg="4">
                                                                <div className="info-group mb-2">
                                                                    <small>
                                                                        Designation:
                                                                    </small>
                                                                    <br></br>
                                                                    <strong>
                                                                        {
                                                                            designation
                                                                        }
                                                                    </strong>
                                                                </div>
                                                            </Col>
                                                            <Col lg="4">
                                                                <div className="info-group mb-2">
                                                                    <small>
                                                                        Employee
                                                                        ID:
                                                                    </small>
                                                                    <br></br>
                                                                    <strong>
                                                                        {empId}
                                                                    </strong>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {/* <Row>
                                                        <Col lg="4">
                                                            <div className="info-group">
                                                                <strong>
                                                                    Mode:
                                                                </strong>
                                                                <p>{type}</p>
                                                            </div>
                                                        </Col>
                                                    </Row> */}
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Card className="mb-4">
                                                <CardHeader>
                                                    <h3 className="mb-0">
                                                        Office Timings
                                                    </h3>
                                                </CardHeader>
                                                <CardBody>
                                                    <div className="pl-lg-4">
                                                        <Row>
                                                            <Col lg="2">
                                                                <div className="info-group">
                                                                    <small>
                                                                        Office
                                                                        Start
                                                                        Time:
                                                                    </small>
                                                                    <br></br>
                                                                    <strong>
                                                                        {
                                                                            officeStartTime
                                                                        }
                                                                    </strong>
                                                                </div>
                                                            </Col>
                                                            <Col lg="2">
                                                                <div className="info-group">
                                                                    <small>
                                                                        Office
                                                                        End
                                                                        Time:
                                                                    </small>
                                                                    <br></br>
                                                                    <strong>
                                                                        {
                                                                            officeEndTime
                                                                        }
                                                                    </strong>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                            <Card className="mb-4">
                                                <CardHeader>
                                                    <h3 className="mb-0">
                                                        User Locations
                                                    </h3>
                                                </CardHeader>
                                                <CardBody>
                                                    <div className="pl-lg-4">
                                                        <Row className="mb-2">
                                                            <Col lg="12">
                                                                <div className="info-group">
                                                                    <small>
                                                                        Location
                                                                        Preference:
                                                                    </small>
                                                                    <br></br>
                                                                    <strong>
                                                                        {selectedOption ===
                                                                        'anywhere'
                                                                            ? 'Anywhere'
                                                                            : 'Selected Locations'}
                                                                    </strong>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {selectedOption ===
                                                            'locations' && (
                                                            <Row>
                                                                <Col lg="12">
                                                                    <div className="info-group">
                                                                        <small>
                                                                            Locations:
                                                                        </small>
                                                                        <br></br>
                                                                        <ul>
                                                                            {locations.map(
                                                                                (
                                                                                    x,
                                                                                    index
                                                                                ) => (
                                                                                    <li
                                                                                        key={
                                                                                            index
                                                                                        }>
                                                                                        <div>
                                                                                            {x.name && (
                                                                                                <>
                                                                                                    <b>
                                                                                                        {
                                                                                                            x.name
                                                                                                        }
                                                                                                    </b>{' '}
                                                                                                    -{' '}
                                                                                                </>
                                                                                            )}

                                                                                            {
                                                                                                x.description
                                                                                            }
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            )}
                                                                        </ul>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <div className="overlay">
                        <Spinner
                            style={{ width: '3rem', height: '3rem' }}
                            color="primary"
                        />
                    </div>
                )}
            </Container>
        </>
    );
};

export default Profile;
