import Profile from 'views/examples/Profile.js';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Register from 'views/examples/Register.js';
import Login from 'views/examples/Login.js';
import SetPassword from 'views/examples/setPassword';
import SignedUp from 'views/examples/signedUp';
import UsersList from 'views/examples/usersList';
import ForgotPassword from 'views/examples/forgotPassword';
import PasswordSet from 'views/examples/passwordSet';
import SignInQ from 'views/examples/signInQ';
import CompanyProfile from 'views/examples/companyProfile';
import Attendance from 'views/examples/attendance';
import MarkAttendance from 'views/examples/markAttendance';
import UserProfile from 'views/examples/userProfile';
import UserAttendance from 'views/examples/userAttendance';
import Dashboard from 'views/examples/dashboard';
import ChangePassword from 'views/examples/changePassword';
import MonthyReport from 'views/examples/monthyReport';

var routes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        icon: 'ni ni-chart-pie-35',
        component: <Dashboard />,
        layout: '/admin',
        sidebar: true,
    },
    {
        path: '/Attendance',
        name: 'Attendance Log',
        icon: 'ni ni-calendar-grid-58',
        component: <Attendance />,
        layout: '/admin',
        sidebar: true,
    },
    {
        path: '/users-list',
        name: 'Users',
        icon: 'ni ni-single-02',
        component: <UsersList />,
        layout: '/admin',
        sidebar: true,
    },
    {
        path: '/user-attendance',
        name: 'My Attendance',
        icon: 'ni ni-single-02 text-yellow',
        component: <UserAttendance />,
        layout: '/nonadmin',
        sidebar: false,
    },
    {
        path: '/mark-attendance',
        name: 'Mark Attendance',
        icon: 'ni ni-circle-08 text-pink',
        component: <MarkAttendance />,
        layout: '/nonadmin',
        sidebar: true,
    },
    {
        path: '/mark-attendance',
        name: 'Mark Attendance',
        icon: 'ni ni-circle-08 text-pink',
        component: <MarkAttendance />,
        layout: '/admin',
        sidebar: false,
    },
    {
        path: '/company-profile',
        name: 'Company Settings',
        icon: 'ni ni-settings-gear-65',
        component: <CompanyProfile />,
        layout: '/admin',
        sidebar: true,
    },
    {
        path: '/montly-report',
        name: 'Attendance Report',
        icon: 'ni ni-archive-2',
        component: <MonthyReport />,
        layout: '/admin',
        sidebar: true,
    },

    {
        path: '/user-profile',
        name: 'My Profile',
        icon: 'ni ni-single-02 text-yellow',
        component: <Profile />,
        layout: '/admin',
        sidebar: false,
    },
    {
        path: '/user-profile-info/:id',
        name: 'User profile',
        icon: 'ni ni-circle-08 text-pink',
        component: <UserProfile />,
        layout: '/admin',
        sidebar: false,
    },
    {
        path: '/login',
        name: 'Login',
        icon: 'ni ni-key-25 text-info',
        component: <Login />,
        layout: '/auth',
        sidebar: false,
    },
    {
        path: '/change-password',
        name: 'Change Password',
        icon: 'ni ni-single-02 text-yellow',
        component: <ChangePassword />,
        layout: '/admin',
        sidebar: false,
    },
    {
        path: '/change-password',
        name: 'Change Password',
        icon: 'ni ni-single-02 text-yellow',
        component: <ChangePassword />,
        layout: '/nonadmin',
        sidebar: false,
    },
    {
        path: '/register',
        name: 'Register',
        icon: 'ni ni-circle-08 text-pink',
        component: <Register />,
        layout: '/auth',
        sidebar: false,
    },
    {
        path: '/registered',
        name: 'Register',
        icon: 'ni ni-circle-08 text-pink',
        component: <SignedUp />,
        layout: '/auth',
        sidebar: false,
    },

    {
        path: '/set-password',
        name: 'Set Password',
        icon: 'ni ni-circle-08 text-pink',
        component: <ForgotPassword />,
        layout: '/auth',
        sidebar: false,
    },
    {
        path: '/set-password/:set',
        name: 'Set Password',
        icon: 'ni ni-circle-08 text-pink',
        component: <PasswordSet />,
        layout: '/auth',
        sidebar: false,
    },
    {
        path: '/onboarding',
        name: 'Onboading',
        icon: 'ni ni-circle-08 text-pink',
        component: <SignInQ />,
        layout: '/auth',
        sidebar: false,
    },

    {
        path: '/user-profile',
        name: 'My Profile',
        icon: 'ni ni-single-02 text-yellow',
        component: <Profile />,
        layout: '/nonadmin',
        sidebar: true,
    },
    // {
    //     path: '/',
    //     name: 'Home',
    //     icon: 'ni ni-single-02 text-yellow',
    //     component: <Home />,
    //     layout: '/',
    //     sidebar: false,
    // },
];
export default routes;
