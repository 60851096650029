import { useState, useEffect, useContext } from 'react';
import { NavLink as NavLinkRRD, Link } from 'react-router-dom';
// nodejs library to set properties for components
import { PropTypes } from 'prop-types';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Collapse,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Media,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col,
} from 'reactstrap';
import { AuthContext } from 'utils/AuthContext';
import { useNavigate } from 'react-router-dom';

var ps;

const AdminSidebar = (props) => {
    const [collapseOpen, setCollapseOpen] = useState();
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    const { logout, loggedOut, setUserInfo, setToken } =
        useContext(AuthContext);
    const navigate = useNavigate();
    const handleLogout = () => {
        logout();
        setUserInfo(null);
        // setToken(null);

        // navigate("/auth/login", { replace: true });
        // return;
    };

    useEffect(() => {
        if (loggedOut) {
            // navigate(0);
            navigate('/auth/login');
            // navigate(0);
        }
    }, [loggedOut]);

    const toggleCollapse = () => {
        setCollapseOpen((data) => !data);
    };
    // closes the collapse
    const closeCollapse = () => {
        setCollapseOpen(false);
    };

    const toggleSubMenu = () => {
        setIsSubMenuOpen(!isSubMenuOpen);
    };
    // creates the links that appear in the left menu / Sidebar
    const createLinks = (routes) => {
        return routes.map((prop, key) => {
            if (prop.sidebar && prop.layout === '/admin') {
                if (prop.children && prop.children.length > 0) {
                    return (
                        <NavItem key={key} caret>
                            <NavLink
                                onClick={toggleSubMenu}
                                style={{ cursor: 'pointer' }}
                                data-toggle="collapse"
                                aria-expanded={isSubMenuOpen}>
                                <i className={prop.icon} />
                                {prop.name}
                            </NavLink>
                            <Collapse isOpen={isSubMenuOpen}>
                                <Nav className="nav-sm flex-column nav">
                                    {createLinks(prop.children)}
                                </Nav>
                            </Collapse>
                        </NavItem>
                    );
                } else {
                    return (
                        <NavItem key={key}>
                            <NavLink
                                to={prop.layout + prop.path}
                                tag={NavLinkRRD}
                                onClick={closeCollapse}>
                                <i className={prop.icon} />
                                {prop.name}
                            </NavLink>
                        </NavItem>
                    );
                }
            }
        });
    };

    const { bgColor, routes, logo } = props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
        navbarBrandProps = {
            to: logo.innerLink,
            tag: Link,
        };
    } else if (logo && logo.outterLink) {
        navbarBrandProps = {
            href: logo.outterLink,
            target: '_blank',
        };
    }

    return (
        <Navbar
            className="navbar-vertical fixed-left navbar-light bg-white"
            expand="md"
            id="sidenav-main">
            <Container fluid>
                {/* Toggler */}
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleCollapse}>
                    <span className="navbar-toggler-icon" />
                </button>

                {logo ? (
                    <NavbarBrand className="pt-0" {...navbarBrandProps}>
                        <img
                            alt={logo.imgAlt}
                            className="navbar-brand-img"
                            src={logo.imgSrc}
                        />
                    </NavbarBrand>
                ) : null}
                {/* User */}
                <Nav className="align-items-center d-md-none">
                    <UncontrolledDropdown nav>
                        <DropdownToggle nav>
                            <Media className="align-items-center">
                                <span className="avatar avatar-sm rounded-circle">
                                    <img
                                        alt="..."
                                        src={`https://ui-avatars.com/api/?name=${
                                            JSON.parse(
                                                localStorage.getItem('userInfo')
                                            )?.name
                                        }&background=BBDEFB&bold=true&rounded=true`}
                                    />
                                </span>
                            </Media>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                                className="noti-title"
                                header
                                tag="div">
                                <h5 className="text-overflow m-0">
                                    Welcome, {userInfo?.name.split(' ')[0]}!
                                </h5>
                            </DropdownItem>

                            <DropdownItem
                                to={
                                    userInfo?.user_type &&
                                    userInfo?.user_type === '1'
                                        ? '/admin/user-profile'
                                        : '/user-profile'
                                }
                                tag={Link}>
                                <i className="ni ni-single-02" />
                                <span>My profile</span>
                            </DropdownItem>
                            <DropdownItem
                                to={
                                    userInfo?.user_type &&
                                    userInfo?.user_type === '1'
                                        ? '/admin/mark-attendance'
                                        : '/mark-attendance'
                                }
                                tag={Link}>
                                <i className="ni ni-calendar-grid-58" />
                                <span>Mark My Attendance</span>
                            </DropdownItem>
                            {userInfo?.user_type &&
                                userInfo?.user_type === '2' && (
                                    <DropdownItem
                                        to="/user-attendance"
                                        tag={Link}>
                                        <i className="ni ni-bullet-list-67" />
                                        <span>My Attendance Log</span>
                                    </DropdownItem>
                                )}
                            <DropdownItem
                                to={
                                    userInfo?.user_type &&
                                    userInfo?.user_type === '1'
                                        ? '/admin/change-password'
                                        : '/change-password'
                                }
                                tag={Link}>
                                <i className="ni ni-lock-circle-open" />
                                <span>Change Password</span>
                            </DropdownItem>

                            <DropdownItem divider />
                            <DropdownItem
                                onClick={(e) => {
                                    // e.preventDefault();
                                    handleLogout();
                                }}>
                                <i className="ni ni-user-run" />
                                <span>Logout</span>
                            </DropdownItem>
                        </DropdownMenu>
                        {/* <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                                className="noti-title"
                                header
                                tag="div">
                                <h6 className="text-overflow m-0">Welcome!</h6>
                            </DropdownItem>

                            <DropdownItem to="/admin/user-profile" tag={Link}>
                                <i className="ni ni-single-02" />
                                <span>My profile</span>
                            </DropdownItem>
                            <DropdownItem to="/admin/user-profile" tag={Link}>
                                <i className="ni ni-settings-gear-65" />
                                <span>Settings</span>
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}>
                                <i className="ni ni-user-run" />
                                <span>Logout</span>
                            </DropdownItem>
                        </DropdownMenu> */}
                    </UncontrolledDropdown>
                </Nav>

                <Collapse navbar isOpen={collapseOpen}>
                    <div className="navbar-collapse-header d-md-none">
                        <Row>
                            {logo ? (
                                <Col className="collapse-brand" xs="6">
                                    {logo.innerLink ? (
                                        <Link to={logo.innerLink}>
                                            <img
                                                alt={logo.imgAlt}
                                                src={logo.imgSrc}
                                            />
                                        </Link>
                                    ) : (
                                        <a href={logo.outterLink}>
                                            <img
                                                alt={logo.imgAlt}
                                                src={logo.imgSrc}
                                            />
                                        </a>
                                    )}
                                </Col>
                            ) : null}
                            <Col className="collapse-close" xs="6">
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    onClick={toggleCollapse}>
                                    <span />
                                    <span />
                                </button>
                            </Col>
                        </Row>
                    </div>

                    <Nav navbar>{createLinks(routes)}</Nav>
                </Collapse>
            </Container>
        </Navbar>
    );
};

AdminSidebar.defaultProps = {
    routes: [{}],
};

AdminSidebar.propTypes = {
    // links that will be displayed inside the component
    routes: PropTypes.arrayOf(PropTypes.object),
    logo: PropTypes.shape({
        // innerLink is for links that will direct the user within the app
        // it will be rendered as <Link to="...">...</Link> tag
        innerLink: PropTypes.string,
        // outterLink is for links that will direct the user outside the app
        // it will be rendered as simple <a href="...">...</a> tag
        outterLink: PropTypes.string,
        // the image src of the logo
        imgSrc: PropTypes.string.isRequired,
        // the alt for the img
        imgAlt: PropTypes.string.isRequired,
    }),
};

export default AdminSidebar;
