import React, { useState, useEffect, useContext } from 'react';
import { Bar, Pie, Line, HorizontalBar, Doughnut } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

// Register the chart components
import {
    Button,
    Badge,
    Card,
    CardHeader,
    CardImg,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Form,
    FormGroup,
    Label,
    Input,
    Table,
    Container,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Pagination,
    InputGroup,
    InputGroupText,
    Spinner,
} from 'reactstrap';
import Header from 'components/Headers/Header.js';
import { AuthContext } from 'utils/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavbarContext } from 'components/Navbars/NavbarContext';

export default function Dashboard() {
    const { setPageTitle } = useNavbarContext();
    const { dashboard, dashboardData } = useContext(AuthContext);

    useEffect(() => {
        document.title = 'Dashboard';
        setPageTitle('Dashboard');
        dashboard();
    }, []);

    // useEffect(() => {
    //     if (dashboardData) {

    //     }
    // }, [dashboardData]);
    const barData = {
        labels: dashboardData?.department_summary_today.map(
            (x) => x.department
        ),
        datasets: [
            {
                label: 'On Time',
                backgroundColor: '#1CAD6F',
                data: dashboardData?.department_summary_today.map(
                    (x) => x.present - x.late
                ),
            },
            {
                label: 'Late',
                backgroundColor: '#FFCD56',
                data: dashboardData?.department_summary_today.map(
                    (x) => x.late
                ),
            },
            {
                label: 'Absent',
                backgroundColor: '#FF6484',
                data: dashboardData?.department_summary_today.map(
                    (x) => x.absent
                ),
            },
        ],
    };

    const previous7DaysData = {
        labels: dashboardData?.summary_last_7_days
            .reverse()
            .map((x) => Object.keys(x)[0]),
        datasets: [
            {
                label: 'On Time',
                backgroundColor: '#1CAD6F',
                data: dashboardData?.summary_last_7_days.map(
                    (x) =>
                        x[Object.keys(x)[0]].present - x[Object.keys(x)[0]].late
                ),
                borderWidth: 0,
                barThickness: 60,
            },
            {
                label: 'Late',
                backgroundColor: '#FFCD56',
                data: dashboardData?.summary_last_7_days.map(
                    (x) => x[Object.keys(x)[0]].late
                ),

                borderWidth: 0,
                barThickness: 60,
            },
            {
                label: 'Absent',
                backgroundColor: '#FF6484',
                data: dashboardData?.summary_last_7_days.map(
                    (x) => x[Object.keys(x)[0]].absent
                ),
                borderWidth: 0,
                barThickness: 60,
            },
        ],
    };

    // Options for the chart
    const options = {
        // type: 'bar',
        scales: {
            xAxes: [
                {
                    stacked: true,
                },
            ],
            yAxes: [
                {
                    stacked: true,
                    beginAtZero: true,
                },
            ],
        },
    };

    const pieData = {
        labels: ['On Time', 'Late', 'Absent'],
        datasets: [
            {
                data: [
                    dashboardData?.total_present_today -
                        dashboardData?.total_late_today,
                    dashboardData?.total_late_today,
                    dashboardData?.total_absent_today,
                ],
                backgroundColor: ['#1CAD6F', '#FFCD56', '#FF6484'],
            },
        ],
    };

    const lineData = {
        labels: [
            'Mon, Dec 10',
            'Tue, Dec 11',
            'Wed, Dec 12',
            'Thu, Dec 13',
            'Fri, Dec 14',
            'Sat, Dec 15',
            'Sun, Dec 16',
        ],
        datasets: [
            {
                label: 'Hours Worked',
                fill: false,
                lineTension: 0.1,
                backgroundColor: '#ff6384',
                borderColor: '#ff6384',
                data: [6, 8, 4, 10, 6, 4, 2],
            },
        ],
    };
    const horizontalBarOptions = {
        scales: {
            xAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };

    return (
        <>
            <Header />
            <ToastContainer />
            {dashboardData ? (
                <Container className="mt-3" flud>
                    <Col className="mb-5 mb-xl-0" xl="12">
                        <Row className="mb-4">
                            <Col lg="3">
                                <Card className="shadow text-center">
                                    <CardBody>
                                        <h5>Total</h5>
                                        <Badge
                                            color="primary"
                                            pill
                                            className="mt-2"
                                            style={{ fontSize: '1.5em' }}>
                                            {dashboardData.total_users}
                                        </Badge>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3">
                                <Card className="shadow text-center">
                                    <CardBody>
                                        <h5>On Time</h5>
                                        <Badge
                                            color="success"
                                            pill
                                            className="mt-2"
                                            style={{ fontSize: '1.5em' }}>
                                            {dashboardData.total_present_today -
                                                dashboardData.total_late_today}
                                        </Badge>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3">
                                <Card className="shadow text-center">
                                    <CardBody>
                                        <h5>Late</h5>
                                        <Badge
                                            color="warning"
                                            pill
                                            className="mt-2"
                                            style={{ fontSize: '1.5em' }}>
                                            {dashboardData.total_late_today}
                                        </Badge>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="3">
                                <Card className="shadow text-center">
                                    <CardBody>
                                        <h5>Absent</h5>
                                        <Badge
                                            color="danger"
                                            pill
                                            className="mt-2"
                                            style={{ fontSize: '1.5em' }}>
                                            {dashboardData.total_absent_today}
                                        </Badge>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <Row className="align-items-center">
                                            <Col>
                                                <h3 className="mb-0">
                                                    Total Attendance Today
                                                </h3>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Doughnut data={pieData} />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="6">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <Row className="align-items-center">
                                            <Col>
                                                <h3 className="mb-0">
                                                    Department Attendance Today
                                                </h3>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <HorizontalBar
                                            data={barData}
                                            options={horizontalBarOptions}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col lg="12">
                                <Card className="shadow">
                                    <CardHeader className="border-0">
                                        <Row className="align-items-center">
                                            <Col>
                                                <h3 className="mb-0">
                                                    Previous 7 Business Days
                                                </h3>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Bar
                                            data={previous7DaysData}
                                            options={options}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Container>
            ) : (
                <div className="overlay">
                    <Spinner
                        style={{ width: '3rem', height: '3rem' }}
                        color="primary"
                    />
                </div>
            )}
        </>
    );
}
