import { useDeferredValue, useEffect } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
} from 'reactstrap';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption,
} from '@reach/combobox';
import '@reach/combobox/styles.css';

import { Loader } from '@googlemaps/js-api-loader';

const PlacesAutocomplete = ({
    onLocationSelect,
    location,
    index,
    addressData,
}) => {
    // const deferredLocation = useDeferredValue(location);
    // const deferredIndex = useDeferredValue(index);
    // const [location, setLocation] = useState("");
    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete({
        value: location ? location.description : '',
    });

    const ref = useOnclickOutside(() => {
        clearSuggestions();
    });

    useEffect(() => {
        if (location) {
            setValue(addressData.description, false);
            clearSuggestions();
        }
    }, [location, setValue, clearSuggestions]);

    const handleInput = (e) => {
        setValue(e.target.value);
    };

    const handleSelect =
        ({ description }) =>
        () => {
            setValue(description, false);
            clearSuggestions();

            getGeocode({ address: description }).then((results) => {
                const { lat, lng } = getLatLng(results[0]);
                let addr = {
                    id: addressData.index,
                    description: description,
                    geocodes: { lat: lat, lng: lng },
                };
                onLocationSelect({ newLocation: addr });
            });
        };
    const renderSuggestions = () =>
        data.map((suggestion) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;

            const suggestionValue = (
                <span
                    dangerouslySetInnerHTML={{
                        __html: `<strong>${main_text}</strong> <small>${secondary_text}</small>`,
                    }}
                />
            );

            return (
                <Combobox className="bg-white">
                    <ComboboxOption
                        key={place_id}
                        value={suggestionValue}
                        onClick={handleSelect(suggestion)}
                    />
                </Combobox>
            );
        });
    return (
        <div ref={ref}>
            <Input
                value={value}
                className="form-control-alternative"
                onChange={handleInput}
                disabled={!ready}
                placeholder="Location Address"
            />
            {status === 'OK' && (
                <ComboboxList style={{ position: 'absolute', zIndex: 1 }}>
                    {renderSuggestions()}
                </ComboboxList>
            )}
        </div>
    );
};

export default PlacesAutocomplete;
