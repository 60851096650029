/*!

=========================================================
* Argon Dashboard React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "utils/AuthContext";
import GoogleLogin from "react-google-login";

const SignInQ = () => {
  const [params] = useSearchParams();
  const signedIn = params.get("signedIn");
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const {
    register,
    isRegistered,
    toastrMsg,
    toastrError,
    setToastrMsg,
    setToastrError,
    gRegistered,
  } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleRegister = () => {
    if (name !== "" && company !== "") {
      register(localStorage.getItem("userEmail"), name, company, true);
      // navigate("/auth/register/signedIn");
    } else {
      toast.error("Answer All the Questions");
    }
  };

  useEffect(() => {
    if (gRegistered) {
      navigate("/admin/Attendance");
      setName("");
      setCompany("");
      localStorage.removeItem("userEmail");
    }
  }, [gRegistered]);

  useEffect(() => {
    if (toastrMsg) {
      toast.success(toastrMsg);
      setToastrMsg(null);
    } else if (toastrError) {
      toast.error(toastrError);
      setToastrError(null);
    }
  }, [toastrMsg, toastrError, setToastrMsg, setToastrError]);
  return (
    <>
      <ToastContainer />
      <Col lg="6" md="8">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Answer a Few Questions...</small>
            </div>
            <Form role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Name"
                    type="text"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-building" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Company"
                    type="text"
                    value={company}
                    onChange={(event) => setCompany(event.target.value)}
                  />
                </InputGroup>
              </FormGroup>

              <Row className="mt-3"></Row>
              <div className="text-center">
                <Button
                  className="mt-4"
                  color="primary"
                  type="button"
                  onClick={() => handleRegister()}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default SignInQ;
