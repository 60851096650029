import React, { useState, useEffect, useContext } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    CustomInput,
    Spinner,
} from 'reactstrap';

import Header from 'components/Headers/Header';
import { BASE_URL } from 'utils/config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from 'utils/AuthContext';
import Select from 'react-select';
import { useNavbarContext } from 'components/Navbars/NavbarContext';

export default function ChangePassword() {
    const {
        resetPassword,
        toastrMsg,
        toastrError,
        setToastrError,
        setToastrMsg,
        checkUserPassword,
        cred,
    } = useContext(AuthContext);
    const { setPageTitle } = useNavbarContext();
    const [passStrength, setPassStrength] = useState(1);
    const [oldPassword, setOldPassword] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [resetDisabled, setResetDisbaled] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = 'Change Password';
        setPageTitle('Change Password');
        checkUserPassword();
    }, []);

    useEffect(() => {
        if (cred && cred === 'no') {
            setOldPassword('dummy');
        }
    }, [cred]);

    useEffect(() => {
        if (toastrMsg) {
            toast.success(toastrMsg);
            setToastrMsg(null);
            setOldPassword('');
            setPassword1('');
            setPassword2('');
            setResetDisbaled(true);
        } else if (toastrError) {
            toast.error(toastrError);
            setToastrError(null);
        }
        setLoading(false);
    }, [toastrMsg, toastrError, setToastrMsg, setToastrError]);

    function checkPasswordStrength(password) {
        // Define regex patterns to check for uppercase, lowercase, numbers, and special characters
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasSpecialChars = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(
            password
        );

        // Check the length of the password
        const isLengthValid = password.length >= 8;

        // Determine the strength based on the criteria
        if (
            hasUppercase &&
            hasLowercase &&
            hasNumbers &&
            hasSpecialChars &&
            isLengthValid
        ) {
            setPassStrength(3);
        } else if (
            (hasUppercase || hasLowercase) &&
            hasNumbers &&
            isLengthValid
        ) {
            setPassStrength(2);
        } else {
            setPassStrength(1);
        }
    }
    function checkComplete() {
        if (password1 !== '' && password2 !== '' && oldPassword !== '') {
            setResetDisbaled(false);
        } else {
            setResetDisbaled(true);
        }
    }

    useEffect(() => {
        checkComplete();
    }, [oldPassword, password1, password2]);

    function handleClick() {
        setLoading(true);
        if (oldPassword !== '' && password1 !== '' && password2 !== '') {
            if (password1 !== password2) {
                toast.error('Passwords did not match');
                setLoading(false);
            } else if (password1 === password2 && passStrength === 1) {
                toast.error(
                    'Your password is weak. Set a strong password of alteast 8 characters long with a combination of one upper case, one lower case, one number and one special character'
                );
                setLoading(false);
            } else if (password1 === password2) {
                resetPassword(oldPassword, password1);
            }
        }
    }

    return (
        <div>
            <Header />
            <Container>
                <ToastContainer />
                <Row className="d-flex justify-content-center align-items-center mt-5">
                    <Col xl="6">
                        <Card>
                            <CardBody>
                                <Form>
                                    <h6 className="heading-small text-muted mb-4">
                                        Reset Password
                                    </h6>
                                    <div className="pl-lg-4">
                                        <FormGroup
                                            hidden={
                                                (cred === 'yes' && false) ||
                                                (cred === 'no' && true)
                                            }>
                                            <label>Current Password</label>
                                            <Input
                                                className="form-control-alternative"
                                                type="password"
                                                value={oldPassword}
                                                onChange={(event) => {
                                                    setOldPassword(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            {password1 && (
                                                <div className="text-muted font-italic">
                                                    <small>
                                                        password strength:{' '}
                                                        {(passStrength ===
                                                            1 && (
                                                            <span className="text-danger font-weight-700">
                                                                weak
                                                            </span>
                                                        )) ||
                                                            (passStrength ===
                                                                2 && (
                                                                <span className="text-info font-weight-700">
                                                                    moderate
                                                                </span>
                                                            )) ||
                                                            (passStrength ===
                                                                3 && (
                                                                <span className="text-success font-weight-700">
                                                                    strong
                                                                </span>
                                                            ))}
                                                    </small>
                                                </div>
                                            )}
                                            <label>New Password</label>
                                            <Input
                                                className="form-control-alternative"
                                                type="password"
                                                id="password1"
                                                value={password1}
                                                onChange={(event) => {
                                                    setPassword1(
                                                        event.target.value
                                                    );
                                                    checkPasswordStrength(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <label>Confirm Password</label>
                                            <Input
                                                className="form-control-alternative"
                                                type="password"
                                                id="password2"
                                                value={password2}
                                                onChange={(event) => {
                                                    setPassword2(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        </FormGroup>
                                        <div className="text-center">
                                            <Button
                                                disabled={
                                                    resetDisabled || loading
                                                }
                                                color="primary"
                                                onClick={(e) => {
                                                    handleClick(
                                                        oldPassword,
                                                        password1,
                                                        password2
                                                    );
                                                    e.preventDefault();
                                                }}
                                                size="sm">
                                                {!loading ? (
                                                    'Reset Password'
                                                ) : (
                                                    <Spinner size="sm" />
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
