import { useContext, useEffect, useState } from 'react';
// node.js library that concatenates classes (strings)
import classnames from 'classnames';
// javascipt plugin for creating charts
import Chart from 'chart.js';
// react plugin used to create charts
import { Line, Bar } from 'react-chartjs-2';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
// reactstrap components
import {
    Button,
    Badge,
    Card,
    CardHeader,
    CardImg,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Form,
    FormGroup,
    Label,
    Input,
    Table,
    Container,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Pagination,
    InputGroup,
    InputGroupText,
    Spinner,
} from 'reactstrap';

// core components
import {
    chartOptions,
    parseOptions,
    chartExample1,
    chartExample2,
} from 'variables/charts.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Header from 'components/Headers/Header.js';
import { AuthContext } from 'utils/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TiPencil, TiTrash, TiPlus } from 'react-icons/ti';
import { BASE_URL } from 'utils/config';
import ReactPaginate from 'react-paginate';
import '../css/pagination.css';
import { useNavbarContext } from 'components/Navbars/NavbarContext';
import Select from 'react-select';
import './overlay.css';

const UserAttendance = (props) => {
    const { getUserAttendance, attendanceList, pages } =
        useContext(AuthContext);
    const { setPageTitle } = useNavbarContext();

    const [filterDate, setFilterDate] = useState('');
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = 'My Attendance';
        setPageTitle('My Attendance Log');
        setLoading(true);
        getUserAttendance();
    }, []);

    useEffect(() => {
        setLoading(false);
    }, [attendanceList]);

    function convertDateFormat(inputDate) {
        if (inputDate) {
            const dateParts = inputDate.split('/');

            const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;

            return formattedDate;
        }
        return inputDate;
    }

    function formatDateToCustomFormat(isoDate) {
        const options = {
            day: 'numeric',
            month: 'long',
            hour: '2-digit',
            year: 'numeric',
            minute: '2-digit',
            second: '2-digit',
        };

        const date = new Date(isoDate);
        const formattedDate = date.toLocaleString('en-US', options);

        return formattedDate;
    }

    const handleDateChange = (dateString) => {
        const date = new Date(dateString);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        setFilterDate(`${day}/${month}/${year}`);
    };

    const parseLocationAndRenderLink = (location) => {
        try {
            const parsedLocation = location;

            const lat = parsedLocation?.geocodes?.lat || 0;
            const lng = parsedLocation?.geocodes?.lng || 0;

            if (!lat || !lng) {
                return 'Unknown Location';
            }

            return (
                <a
                    href={`https://www.google.com/maps?q=${lat},${lng}&hl=en`}
                    target="_blank"
                    rel="noopener noreferrer">
                    {parsedLocation?.name
                        ? parsedLocation.name
                        : 'View in Google Maps'}
                </a>
            );
        } catch (error) {
            console.error('Error parsing location JSON:', error);
            return 'Unknown';
        }
    };

    const renderLink = (location) => {
        try {
            const parsedLocation = location;
            const lat = parsedLocation?.geocodes?.lat || 0;
            const lng = parsedLocation?.geocodes?.lng || 0;

            if (!lat || !lng) {
                return 'Unknown Location';
            }

            return `https://www.google.com/maps?q=${lat},${lng}&hl=en`;
        } catch (error) {
            console.error('Error parsing location JSON:', error);
            return 'Unknown';
        }
    };

    return (
        <>
            <Header />
            <Container className="mt-4" fluid>
                <Row>
                    <ToastContainer />
                    {!loading ? (
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card
                                className="shadow"
                                style={{ height: '42rem' }}>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center justify-content-between">
                                        <Form className="d-flex flex-row  align-items-center w-25">
                                            <FormGroup
                                                className="mr-4"
                                                style={{ flex: 1 }}>
                                                <label
                                                    style={{ display: 'block' }}
                                                    className="form-control-label"
                                                    htmlFor="filter-status">
                                                    Date
                                                </label>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle className="p-0 dropdown-menu-arrow">
                                                        <Input
                                                            className="form-control-alternative"
                                                            placeholder="dd/mm/yyy"
                                                            size="sm"
                                                            value={filterDate}
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                setFilterDate(
                                                                    event.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                    </DropdownToggle>

                                                    <DropdownMenu
                                                        style={{
                                                            width: 'auto',
                                                            padding: '10px',
                                                            transform:
                                                                'translateX(-50%)',
                                                            left: '50%',
                                                            top: '100%',
                                                            marginTop: '5px',
                                                            position:
                                                                'absolute',
                                                            zIndex: 1000,

                                                            overflowY: 'auto',
                                                        }}>
                                                        <Calendar
                                                            direction="vertical"
                                                            onChange={
                                                                handleDateChange
                                                            }
                                                        />
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </FormGroup>

                                            <Button
                                                className="form-control-alternative mt-2 ml--1"
                                                color="primary"
                                                size="sm"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setLoading(true);
                                                    getUserAttendance({
                                                        page: page + 1,

                                                        date: convertDateFormat(
                                                            filterDate
                                                        ),
                                                    });

                                                    setPage(0);
                                                }}>
                                                Filter
                                            </Button>

                                            <Button
                                                className="form-control-alternative mt-2"
                                                color="primary"
                                                size="sm"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setLoading(true);
                                                    setFilterDate('');

                                                    getUserAttendance();
                                                }}>
                                                Reset
                                            </Button>
                                        </Form>
                                    </Row>
                                </CardHeader>
                                <div
                                    style={{
                                        height: '100%',
                                        overflowY: 'auto',
                                    }}>
                                    <Table
                                        // className="align-items-center"
                                        style={{}}>
                                        <thead
                                            className="thead-light"
                                            style={{
                                                position: 'sticky',
                                                top: '0',
                                                zIndex: '1',
                                            }}>
                                            <tr>
                                                <th scope="col">Date</th>
                                                <th scope="col">Location</th>
                                                <th scopr="col">Image</th>
                                                <th scope="col">Added By</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {attendanceList &&
                                                attendanceList.map((x) => {
                                                    return (
                                                        <tr>
                                                            <td scope="row">
                                                                {formatDateToCustomFormat(
                                                                    `${x.date}T${x.time}`
                                                                )}
                                                            </td>
                                                            <td scope="row">
                                                                {(x.location &&
                                                                    parseLocationAndRenderLink(
                                                                        x.location
                                                                    )) ||
                                                                    (x.unknown_location ? (
                                                                        <a
                                                                            href={`https://www.google.com/maps?q=${x?.unknown_location?.lat},${x.unknown_location?.lng}&hl=en`}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer">
                                                                            {
                                                                                'Offline'
                                                                            }
                                                                        </a>
                                                                    ) : (
                                                                        'Unknown'
                                                                    ))}
                                                            </td>
                                                            <td>
                                                                {x.img_attendance ? (
                                                                    <a
                                                                        href={`${BASE_URL}${x?.img_attendance}`}
                                                                        target="_blank">
                                                                        <CardImg
                                                                            alt="Card image cap"
                                                                            src={`${BASE_URL}${x?.img_attendance}`}
                                                                            style={{
                                                                                height: 50,
                                                                                width: 50,
                                                                            }}
                                                                            top
                                                                            width=""
                                                                        />
                                                                    </a>
                                                                ) : (
                                                                    'Unvailable'
                                                                )}
                                                            </td>

                                                            <td>
                                                                {
                                                                    x.added_by
                                                                        .first_name
                                                                }{' '}
                                                                {
                                                                    x.added_by
                                                                        .last_name
                                                                }{' '}
                                                                {x.added_by
                                                                    .id !==
                                                                x.user.id
                                                                    ? '(Admin)'
                                                                    : ''}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </Table>
                                    {pages > 1 && (
                                        <div
                                            className="d-flex flex-row justify-content-center align-items-center mt-2"
                                            size="sm">
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel="Next"
                                                previousLabel="Previous"
                                                forcePage={page}
                                                onPageChange={(e) => {
                                                    setPage(e.selected);
                                                    setLoading(true);
                                                    getUserAttendance({
                                                        page: e.selected + 1,

                                                        date: convertDateFormat(
                                                            filterDate
                                                        ),
                                                    });
                                                }}
                                                pageCount={pages}
                                                marginPagesDisplayed={2}
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                breakClassName="break"
                                                size="sm"
                                            />
                                        </div>
                                    )}
                                </div>
                            </Card>
                        </Col>
                    ) : (
                        <div className="overlay">
                            <Spinner
                                style={{ width: '3rem', height: '3rem' }}
                                color="primary"
                            />
                        </div>
                    )}
                </Row>
            </Container>
        </>
    );
};

export default UserAttendance;
