/* global dataLayer gtag */
import React, { useState, useEffect, useContext } from 'react';
import '../css/Home.css';
import CallToAction from '../../assets/img/home/call-to-action.png';
import HeroImage from '../../assets/img/home/gif_2.gif';

import iphone from '../../assets/img/home/iphone.png';
import logo from '../../assets/img/home/logo.png';
import PorfilePicture from '../../assets/img/home/profile-picture-one.jpg';

import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from 'utils/AuthContext';
import '../../assets/css/main.css';
// import 'ionicons/css/ionicons.min.css';

// import '../../assets/vendor/bootstrap/css/bootstrap.min.css';
// import '../../assets/vendor/bootstrap/js/bootstrap.bundle.min.js';
// import '../../assets/js/main.js';
import 'aos/dist/aos.css';
import AOS from 'aos';
import {
    faCalendar,
    faCreditCard,
    faGlobe,
    faHandPointer,
    faDesktop,
    faUsers,
    faArrowUp,
    faCircleCheck,
    faMapMarker,
    faEnvelope,
    faCheckCircle,
    faArrowAltCircleRight,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import logo from '../../assets/img/home/logo.png';
import heroBgLight from '../../assets/img/home/hero-bg-light.webp';
import infoBotGif from '../../assets/img/home/infoBot_GIF.gif';
import sec1 from '../../assets/img/home/sc-1.png';
import sec2 from '../../assets/img/home/sc-2.png';
import sec3 from '../../assets/img/home/sc-3.png';
import tab1 from '../../assets/img/home/tabs-1.png';
import tab2 from '../../assets/img/home/tabs-2.png';
import tab3 from '../../assets/img/home/tabs-3.png';
import dashboardImage from '../../assets/img/home/Dashboard.png';

export default function Home() {
    const [userToken, setUserToken] = useState('');
    const [currency, setCurrency] = useState('USD');
    const price = currency === 'USD' ? '$10 / month' : '₹500 / month';
    const changePrice = (e) => {
        setCurrency(e.target.value);
    };
    const { setToken } = useContext(AuthContext);
    useEffect(() => {
        document.title = 'PinPoint';
        AOS.init({
            duration: 600,
            easing: 'ease-in-out',
            once: true,
            mirror: false,
        });

        window.dataLayer = window.dataLayer || [];
        function gtag() {
            dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'AW-16595674367');

        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        setUserToken(userInfo?.token);
        // return () => {
        //     document.head.removeChild(homeCSS);
        // };
    }, []);
    function gtag_report_conversion(url) {
        var callback = function () {
            if (typeof url != 'undefined') {
                window.location = url;
            }
        };
        gtag('event', 'conversion', {
            send_to: 'AW-16595674367/pghOCMimi7cZEP_Jt-k9',
            value: 1.0,
            currency: 'INR',
            event_callback: callback,
        });
        return false;
    }
    return (
        <div className="body-home">
            <nav className="navbar navbar-default navbar-expand-lg fixed-top custom-navbar">
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="icon ion-md-menu"></span>
                </button>
                <img
                    src={logo}
                    className="img-fluid nav-logo-mobile"
                    alt="Company Logo"
                    width="200px!important"
                    style={{ maxWidth: '200px!important;' }}
                />
                <div
                    className="collapse navbar-collapse"
                    id="navbarNavDropdown">
                    <div className="container">
                        <img
                            src={logo}
                            className="img-fluid nav-logo-desktop"
                            alt="Company Logo"
                            width="200px!important"
                            style={{ maxWidth: '200px!important;' }}
                        />
                        <ul
                            className="navbar-nav ml-auto nav-right"
                            data-easing="easeInOutExpo"
                            data-speed="1250"
                            data-offset="65">
                            <li className="nav-item nav-custom-link align-self-center">
                                <a className="nav-link" href="#hero">
                                    Home{' '}
                                    <i className="icon ion-ios-arrow-forward icon-mobile"></i>
                                </a>
                            </li>
                            <li className="nav-item nav-custom-link align-self-center">
                                <a className="nav-link" href="#marketing">
                                    Features{' '}
                                    <i className="icon ion-ios-arrow-forward icon-mobile"></i>
                                </a>
                            </li>

                            <li className="nav-item nav-custom-link align-self-center">
                                <a className="nav-link" href="#pricing">
                                    Pricing{' '}
                                    <i className="icon ion-ios-arrow-forward icon-mobile"></i>
                                </a>
                            </li>

                            <li className="nav-item nav-custom-link mr-2">
                                <a
                                    className="btn btn-home btn-outline-primary"
                                    href="/admin/login">
                                    Login{' '}
                                    <i className="icon ion-ios-arrow-forward icon-mobile"></i>
                                </a>
                            </li>

                            <li className="nav-item nav-custom-link">
                                <a
                                    className="btn btn-home btn-primary"
                                    target="_blank"
                                    href="/auth/register"
                                    style={{
                                        color: 'white',
                                        textDecoration: 'none',
                                    }}>
                                    <span
                                        style={{
                                            color: 'white',
                                            textDecoration: 'none',
                                        }}>
                                        Try for Free
                                    </span>

                                    <i className="icon ion-ios-arrow-forward icon-mobile"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <section id="hero">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                            <img
                                src={HeroImage}
                                className="img-fluid"
                                alt="Demo image"
                            />
                        </div>
                        <div className="col-md-7 content-box hero-content">
                            <span>No Extera Device Needed</span>
                            <h1 className="h1-home">
                                Mobile based Attendance <br />
                                <b>With Location and Image</b>
                            </h1>
                            <p>
                                To increase the productivity and transparency at
                                work place
                            </p>
                            <a
                                href="/auth/register"
                                target="_blank"
                                className="btn btn-home btn-regular">
                                Start Now
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section id="marketing">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="content-box">
                                <span>All Data in clicks</span>
                                <h2 className="h2-home">
                                    Daily & Monthly Reports
                                </h2>
                                <p>
                                    No need to reconcile all attendance every
                                    month. PinPoint will give you everything in
                                    easy to read fashion, in few clicks.
                                </p>
                                <a
                                    href="/auth/register"
                                    target="_blank"
                                    className="btn btn-home btn-regular">
                                    Start Free Trial
                                </a>
                            </div>
                        </div>
                        <div className="col-md-7 d-flex align-items-center">
                            <img
                                src={dashboardImage}
                                className="img-fluid"
                                alt="Demo image"
                            />
                        </div>
                    </div>

                    <div className="title-block">
                        <h2 className="h2-home">Features that You need</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="testimonial-box">
                                <div className="row personal-info">
                                    <div className="col-md-10 col-xs-10">
                                        <h6>
                                            Freeze office Time for Individuals
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="testimonial-box">
                                <div className="row personal-info">
                                    <div className="col-md-10 col-xs-10">
                                        <h6>Option to restirct Location</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="testimonial-box">
                                <div className="row personal-info">
                                    <div className="col-md-10 col-xs-10">
                                        <h6>Map View of place of Punch</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="testimonial-box">
                                <div className="row personal-info">
                                    <div className="col-md-10 col-xs-10">
                                        <h6>Image with each Punch</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="testimonial-box">
                                <div className="row personal-info">
                                    <div className="col-md-10 col-xs-10">
                                        <h6>Admin can verify Attendance</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="testimonial-box">
                                <div className="row personal-info">
                                    <div className="col-md-10 col-xs-10">
                                        <h6>Easy access to attendance Logs</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="pricing">
                <div className="container">
                    <div className="title-block">
                        <h2 className="h2-home">Plans and Pricing</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="pricing-box">
                                <h3 className="demo">
                                    Small Team upto 20 Members
                                </h3>
                                <h4 className="h4-home">₹ 1000 / Month</h4>
                                <p>All features</p>
                                <div className="divider-light"></div>
                                <ul>
                                    <li>
                                        <i className="icon ion-md-checkmark-circle-outline demo"></i>
                                        Add Employees
                                    </li>
                                    <li>
                                        <i className="icon ion-md-checkmark-circle-outline demo"></i>
                                        Bulk Add
                                    </li>
                                    <li>
                                        <i className="icon ion-md-checkmark-circle-outline demo"></i>
                                        Atendance with Location and Image
                                    </li>
                                    <li>
                                        <i className="icon ion-md-checkmark-circle-outline demo"></i>
                                        Daily and Monthly Reports
                                    </li>
                                    <li>
                                        <i className="icon ion-md-checkmark-circle-outline demo"></i>
                                        Support on Email & Phone
                                    </li>
                                </ul>
                                <div className="text-center">
                                    <a
                                        href="/auth/register"
                                        target="_blank"
                                        className="btn btn-home btn-demo">
                                        Start Now
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="pricing-box">
                                <h3 className="demo">Large Team </h3>
                                <h4 className="h4-home">₹ 50 / User / Month</h4>
                                <p>All features</p>
                                <div className="divider-light"></div>
                                <ul>
                                    <li>
                                        <i className="icon ion-md-checkmark-circle-outline demo"></i>
                                        Add Employees
                                    </li>
                                    <li>
                                        <i className="icon ion-md-checkmark-circle-outline demo"></i>
                                        Bulk Add
                                    </li>
                                    <li>
                                        <i className="icon ion-md-checkmark-circle-outline demo"></i>
                                        Atendance with Location and Image
                                    </li>
                                    <li>
                                        <i className="icon ion-md-checkmark-circle-outline demo"></i>
                                        Daily and Monthly Reports
                                    </li>
                                    <li>
                                        <i className="icon ion-md-checkmark-circle-outline demo"></i>
                                        Support on Email & Phone
                                    </li>
                                </ul>
                                <div className="text-center">
                                    <a
                                        href="/auth/register"
                                        target="_blank"
                                        className="btn  btn-demo">
                                        Start Now
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="call-to-action">
                <div className="container text-center">
                    <h2 className="h2-home">
                        Employee Attendance is now super easy
                    </h2>
                    <div className="title-block">
                        <p>Everything on cloud, all that you need</p>
                        <a
                            href="/auth/register"
                            target="_blank"
                            className="btn btn-home btn-regular">
                            Get Started Now
                        </a>
                    </div>
                </div>
            </section>

            <footer className="footer-home">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <h5 className="h5-home">Sections</h5>
                            <ul>
                                <li>
                                    <a href="#hero">Home</a>
                                </li>
                                <li>
                                    <a href="#marketing">Features</a>
                                </li>
                                <li>
                                    <a href="#pricing">Pricing</a>
                                </li>
                                <li>
                                    <a href="#">Login</a>
                                </li>
                                <li>
                                    <a href="#">Signup</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h5 className="h5-home">Download App</h5>
                            <ul>
                                <a
                                    className="btn btn-home btn-primary"
                                    href="https://pinpoint.idearise.co/api/media/android/pinpoint.apk"
                                    style={{ color: 'white' }}>
                                    <span>For Android</span>
                                </a>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h5 className="h5-home">Contact</h5>
                            <ul>
                                <li>
                                    <a
                                        href="mailto:pinpoint@idearise.co"
                                        className="external-links">
                                        pinpoint@idearise.co
                                    </a>
                                </li>
                                <li>
                                    <a>Bangaluru, India</a>
                                </li>
                                <li>
                                    <a>+91-6362176153</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h5 className="h5-home">Idea Rise</h5>
                            <p>
                                IR is a Technology Company, pioneering in
                                creating softwares for Business in India and
                                across the Globe. Started in 2016, IR has worked
                                with 300+ Companies of all size and all
                                industries.
                            </p>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="row">
                        <div className="col-md-6 col-xs-12">
                            <small>
                                2018 &copy; All rights reserved. Made by{' '}
                                <a
                                    href="http://templune.com/"
                                    target="blank"
                                    className="external-links">
                                    Templune
                                </a>
                            </small>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

const HeroSection = () => (
    <section id="home" className="hero section">
        <div className="hero-bg">
            <img src={heroBgLight} alt="hero background" />
        </div>
        <div className="container text-center">
            <div className="d-flex flex-column justify-content-center align-items-center">
                <h1 data-aos="fade-up">
                    Create your <span>Personalized AI Chat Agent</span> for your
                    website
                </h1>
                <p data-aos="fade-up" data-aos-delay="100">
                    Using the power of Chat GPT for any kind of Business, any
                    Language. All in few Clicks.
                    <br />
                </p>
                <img
                    src={infoBotGif}
                    className="img-fluid hero-img"
                    alt="Info Bot"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                    style={{ border: '1px solid grey' }}
                />
                <br />
                <br />
                <div className="d-flex" data-aos="fade-up" data-aos-delay="200">
                    <Link to="/register" className="btn-get-started">
                        Get Started
                    </Link>
                </div>
            </div>
        </div>
    </section>
);

const ServiceItem = ({ icon, title, description, delay }) => (
    <div
        className="col-xl-4 col-lg-6"
        data-aos="fade-up"
        data-aos-delay={delay}>
        <div className="service-item d-flex">
            <div className="icon flex-shrink-0">
                <i>
                    <FontAwesomeIcon icon={icon} />
                </i>
            </div>
            <div>
                <h4 className="h4-home title">
                    <Link to="/register" className="stretched-link">
                        {title}
                    </Link>
                </h4>
                <p className="description">{description}</p>
            </div>
        </div>
    </div>
);

const AboutSection = () => (
    <section id="about" className="about section">
        <div className="container">
            <div className="row gy-4">
                <div
                    className="col-lg-6 content"
                    data-aos="fade-up"
                    data-aos-delay="100">
                    <p className="who-we-are">WHY INFOBOT</p>
                    <h3 className="h3-home">
                        Artificial Intelligence is the Present
                    </h3>
                    <p className="fst-italic">
                        Your prospect is looking for information all the time.
                        We want you to give them all the information, without
                        waiting for a human being to address the enquiries.
                        InfoBOT will be your AI Customer Support Agent,
                        answering the questions like a human being. We use the
                        Power of ChatGPT to produce best results.
                    </p>
                    <ol>
                        <li>
                            {' '}
                            <span>Create an Account</span>
                        </li>
                        <li>
                            {' '}
                            <span>Add ChatGPT API Key</span>
                        </li>
                        <li>
                            {' '}
                            <span>
                                Upload Documents with all information about your
                                business
                            </span>
                        </li>
                        <li>
                            {' '}
                            <span>Add a Button on your website</span>
                        </li>
                    </ol>
                    <b>You are ready with your AI Chat Bot.</b>
                </div>
                <div
                    className="col-lg-6 about-images"
                    data-aos="fade-up"
                    data-aos-delay="200">
                    <div className="row gy-4">
                        <div className="col-lg-6">
                            <img
                                src={sec1}
                                className="img-fluid"
                                alt="screenshot 1"
                            />
                        </div>
                        <div className="col-lg-6">
                            <div className="row gy-4">
                                <div className="col-lg-12">
                                    <img
                                        src={sec2}
                                        className="img-fluid"
                                        alt="screenshot 2"
                                    />
                                </div>
                                <div className="col-lg-12">
                                    <img
                                        src={sec3}
                                        className="img-fluid"
                                        alt="screenshot 3"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

const FeaturesSection = () => (
    <section id="features" className="features section">
        {/* Section Title */}
        <div className="container section-title" data-aos="fade-up">
            <h2 className="h2-home">Features</h2>
            <p>We do just one thing and we do it Great !!!</p>
        </div>

        <div className="container">
            <div className="row justify-content-between">
                <div className="col-lg-5 d-flex align-items-center">
                    <ul
                        className="nav nav-tabs"
                        data-aos="fade-up"
                        data-aos-delay="100">
                        <li className="nav-item">
                            <a
                                className="nav-link active show"
                                data-bs-toggle="tab"
                                data-bs-target="#features-tab-1">
                                <i>
                                    <FontAwesomeIcon icon={faHandPointer} />
                                </i>

                                <div>
                                    <h4 className="h4-home d-none d-lg-block">
                                        Button on Your Website to Access Chat
                                        Bot
                                    </h4>
                                    <p>
                                        Name the button as per your creativity.
                                        Place it where you are confident will
                                        help you get most visitors.
                                    </p>
                                </div>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                data-bs-target="#features-tab-2">
                                <i>
                                    <FontAwesomeIcon icon={faDesktop} />
                                </i>
                                <div>
                                    <h4 className="h4-home d-none d-lg-block">
                                        Fully Customizable Chat Page
                                    </h4>
                                    <p>
                                        Change the color of header, body, chat
                                        messages, logo to match your business
                                        theme.
                                    </p>
                                </div>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                data-bs-target="#features-tab-3">
                                <i>
                                    <FontAwesomeIcon icon={faUsers} />
                                </i>
                                <div>
                                    <h4 className="d-none d-lg-block">
                                        See what your prospect asked about you
                                    </h4>
                                    <p>
                                        History of all interactions by your
                                        users, accessible at one place.
                                    </p>
                                </div>
                            </a>
                        </li>
                    </ul>
                    {/* End Tab Nav */}
                </div>

                <div className="col-lg-6">
                    <div
                        className="tab-content"
                        data-aos="fade-up"
                        data-aos-delay="200">
                        <div
                            className="tab-pane fade active show"
                            id="features-tab-1">
                            <img src={tab1} alt="" className="img-fluid" />
                        </div>
                        {/* End Tab Content Item */}

                        <div className="tab-pane fade" id="features-tab-2">
                            <img src={tab2} alt="" className="img-fluid" />
                        </div>
                        {/* End Tab Content Item */}

                        <div className="tab-pane fade" id="features-tab-3">
                            <img src={tab3} alt="" className="img-fluid" />
                        </div>
                        {/* End Tab Content Item */}
                    </div>
                </div>
            </div>
        </div>
    </section>
);

const PricingSection = ({ currency, price, changePrice }) => (
    <section id="pricing" className="pricing section">
        <div className="container section-title" data-aos="fade-up">
            <h2 className="h2-home">Pricing</h2>
            <p>Simplest Pricing you will ever get</p>
        </div>
        <div className="container">
            <div className="row gy-4">
                <div
                    className="col-lg-4"
                    data-aos="zoom-in"
                    data-aos-delay="100"></div>
                <div
                    className="col-lg-4"
                    data-aos="zoom-in"
                    data-aos-delay="100">
                    <div className="pricing-item">
                        <h3 className="h3-home">Your Plan</h3>
                        <p className="description">
                            Chat GPT API Cost will be additional expense for you
                        </p>
                        <div className="row">
                            <div className="col-4">
                                <select
                                    className="form-control mt-2"
                                    style={{ WebkitAppearance: 'listbox' }}
                                    value={currency}
                                    onChange={changePrice}>
                                    <option value="USD">USD</option>
                                    <option value="INR">INR</option>
                                </select>
                            </div>
                            <div className="col-8">
                                <h4 id="price_value">
                                    <sup>{currency === 'USD' ? '$' : '₹'}</sup>
                                    {currency === 'USD' ? '10' : '500'}
                                    <span> / month</span>
                                </h4>
                            </div>
                        </div>
                        <Link to="/auth/register" className="cta-btn">
                            Start a free trial
                        </Link>
                        <p className="text-center small">
                            No credit card required
                        </p>
                    </div>
                </div>
                <div
                    className="col-lg-4"
                    data-aos="zoom-in"
                    data-aos-delay="100"></div>
            </div>
        </div>
    </section>
);

const ContactSection = () => (
    <section id="contact" className="contact section">
        <div className="container section-title" data-aos="fade-up">
            <h2 className="h2-home">Contact</h2>
        </div>
        <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row gy-4">
                <div className="col-lg-4">
                    <div
                        className="info-item d-flex flex-column justify-content-center align-items-center"
                        data-aos="fade-up"
                        data-aos-delay="200">
                        <h3 className="h3-home">Address</h3>
                        <p>Bangalore, India</p>
                    </div>
                </div>
                {/* End Info Item */}

                <div className="col-lg-4 col-md-6">
                    <div
                        className="info-item d-flex flex-column justify-content-center align-items-center"
                        data-aos="fade-up"
                        data-aos-delay="300">
                        <h3 className="h3-home">Whatsapp</h3>
                        <p>
                            <a
                                href="https://wa.me/+916362176153"
                                target="_blank"
                                rel="noopener noreferrer">
                                +91 6362 176 153
                            </a>
                        </p>
                    </div>
                </div>
                {/* End Info Item */}

                <div className="col-lg-4 col-md-6">
                    <div
                        className="info-item d-flex flex-column justify-content-center align-items-center"
                        data-aos="fade-up"
                        data-aos-delay="400">
                        <h3 className="h3-home">Email</h3>
                        <p>
                            <a href="mailto:infobot@idearise.co">
                                infobot@idearise.co
                            </a>
                        </p>
                    </div>
                </div>
                {/* End Info Item */}
            </div>
        </div>
    </section>
);

const Footer = () => (
    <footer id="footer" className="footer position-relative mb-2">
        <div className="container copyright text-center mt-4 mb-0 pb-0 border-none">
            <p className="border-none box-shadow-none">
                © <span>Copyright</span>{' '}
                <strong className="px-1 sitename">
                    Idea Rise Technologies
                </strong>
                <span>All Rights Reserved</span>
                <br />
                {/* <div className="d-flex align-items-center justify-content-cetner"> */}
                <Link target="_blank" to="/privacy_policy">
                    Privacy policy
                </Link>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <Link target="_blank" to="terms_of_use">
                    Terms of Use
                </Link>
                {/* </div> */}
            </p>
            <div className="credits">
                Designed by{' '}
                <a href="https://bootstrapmade.com/">BootstrapMade</a>
            </div>
        </div>
    </footer>
);

const ScrollTop = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollTopButton = document.getElementById('scroll-top');
            if (window.scrollY > 100) {
                scrollTopButton.classList.add('active');
            } else {
                scrollTopButton.classList.remove('active');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <a
            href="#"
            id="scroll-top"
            className="scroll-top d-flex align-items-center justify-content-center my-0"
            onClick={(e) => {
                e.preventDefault();
                scrollToTop();
            }}>
            <i className="my-0">
                <FontAwesomeIcon icon={faArrowUp} color="white" />
            </i>
        </a>
    );
};
