import { useContext, useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Spinner,
} from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import { useSearchParams, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

import { AuthContext } from 'utils/AuthContext';

const ForgotPassword = () => {
    const {
        register,
        userInfo,
        sendOtp,
        toastrMsg,
        toastrError,
        setToastrMsg,
        setToastrError,
        toastrEmailMsg,
        toastrEmailError,
        setToastrEmailMsg,
        setToastrEmailError,
        toastrOtpMsg,
        toastrOtpError,
        setToastrOtpMsg,
        setToastrOtpError,
        verifyOtp,
        setPassword,
        otpSent,
        setOtpSent,
    } = useContext(AuthContext);
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [username, setUsername] = useState('');
    const [passStrength, setPassStrength] = useState(1);
    const [disabled, setDisabled] = useState(true);
    const [emailDisable, setEmailDisable] = useState(false);
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailDisabled, setEmailDisabled] = useState(true);
    const [otpDisabled, setOtpDisabled] = useState(true);
    const [passDisabled, setPassDisabled] = useState(true);
    const navigate = useNavigate();

    function checkPasswordStrength(password) {
        // Define regex patterns to check for uppercase, lowercase, numbers, and special characters
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasSpecialChars = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(
            password
        );

        // Check the length of the password
        const isLengthValid = password.length >= 8;

        // Determine the strength based on the criteria
        if (
            hasUppercase &&
            hasLowercase &&
            hasNumbers &&
            hasSpecialChars &&
            isLengthValid
        ) {
            setPassStrength(3);
        } else if (
            (hasUppercase || hasLowercase) &&
            hasNumbers &&
            isLengthValid
        ) {
            setPassStrength(2);
        } else {
            setPassStrength(1);
        }
    }

    useEffect(() => {
        document.title = 'Forgot Password';
    }, []);

    useEffect(() => {
        if (toastrMsg) {
            navigate('/auth/set-password/set');
            toast.success(toastrMsg);
            setToastrMsg(null);
            setEmailSent(false);
            setOtpSent(false);
        } else if (toastrError) {
            toast.error(toastrError);
            setToastrError(null);
            setPassword1('');
            setPassword2('');
        }
        setLoading(false);
    }, [toastrMsg, toastrError, setToastrMsg, setToastrError]);

    useEffect(() => {
        if (toastrEmailMsg) {
            setEmailDisable(true);
            toast.success(toastrEmailMsg);
            setToastrEmailMsg(null);
        } else if (toastrEmailError) {
            toast.error(toastrEmailError);
            setToastrEmailError(null);
        }
        setLoading(false);
        // setDisabled(false);
    }, [
        toastrEmailMsg,
        setToastrEmailMsg,
        toastrEmailError,
        setToastrEmailError,
    ]);

    useEffect(() => {
        if (toastrOtpMsg) {
            // toast.success(toastrOtpMsg);
            setEmailSent(true);
            setToastrOtpMsg(null);
        } else if (toastrOtpError) {
            toast.error(toastrOtpError);
            setToastrOtpError(null);
        }
        setLoading(false);
        setOtpDisabled(false);
    }, [toastrOtpMsg, setToastrOtpMsg, toastrOtpError, setToastrOtpError]);

    useEffect(() => {
        checkComplete();
    }, [password1, password2]);

    useEffect(() => {
        checkEmailComplete();
    }, [email]);

    useEffect(() => {
        checkOtpComplete();
    }, [otp]);

    const emailValidator = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    function checkComplete() {
        if (password1 !== '' && password2 !== '') {
            setPassDisabled(false);
        }
    }

    function checkEmailComplete() {
        if (email !== '') {
            setDisabled(false);
        }
    }

    function checkOtpComplete() {
        if (otp !== '') {
            setOtpDisabled(false);
        }
    }

    function handleSendOtp() {
        setLoading(true);
        if (email && email !== '') {
            if (emailValidator(email)) {
                setDisabled(true);
                sendOtp(email, otp);
            } else {
                toast.error('Enter a valid email');
                setLoading(false);
            }
        } else {
            toast.error('Enter email');
            setLoading(false);
        }
    }

    function handleVerifyOtp() {
        setOtpDisabled(true);
        setLoading(true);
        if (otp && otp !== '') {
            verifyOtp(email, otp);
        } else {
            toast.error('Enter OTP');
            setLoading(false);
        }
    }

    function handleClick(password1, password2) {
        setPassDisabled(true);
        setLoading(true);

        // navigate("/admin/icons");
        if (password1 !== '' && password2 !== '') {
            if (password1 !== password2) {
                toast.error('Passwords did not match');
                setPassDisabled(false);
                setLoading(false);
            } else if (password1 === password2 && passStrength === 1) {
                toast.error(
                    'Your password is weak. Set a strong password of alteast 8 characters long with a combination of one upper case, one lower case, one number and one special character'
                );
                setPassDisabled(false);
                setLoading(false);
            } else if (password1 === password2) {
                setPassword(email, password1);
            }
        }
    }

    useEffect(() => {
        if (JSON.parse(localStorage.getItem('userToken'))) {
            navigate('/admin');

            setUsername('');
            setPassword('');
        }
    });

    // useEffect(() => {
    //     if (userInfo !== null) {
    //     }
    //     if (JSON.parse(localStorage.getItem('userInfo'))?.token) {
    //     }
    // }, [userInfo]);
    return !emailSent ? (
        <>
            <ToastContainer />
            <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <Form role="form">
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-email-83" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        disabled={otpSent}
                                        placeholder="Email"
                                        type="email"
                                        autoComplete="new-email"
                                        value={email}
                                        onChange={(event) => {
                                            setEmail(event.target.value);
                                        }}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup hidden={!otpSent}>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="OTP"
                                        type="number"
                                        inputMode="numeric"
                                        autoComplete="new-otp"
                                        value={otp}
                                        onChange={(event) => {
                                            setOtp(event.target.value);
                                        }}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <Row className="mt-3">
                                <Col className="text-center" xs="12">
                                    <small>Remembered Password? </small>
                                    <Link to="/auth/login">
                                        <small>Login</small>
                                    </Link>
                                </Col>
                            </Row>

                            {otpSent ? (
                                <div className="text-center">
                                    <Button
                                        disabled={otpDisabled}
                                        className="my-4"
                                        color="primary"
                                        type="button"
                                        onClick={() => {
                                            handleVerifyOtp(email);
                                        }}>
                                        {!loading ? (
                                            'Verify OTP'
                                        ) : (
                                            <Spinner size="sm" />
                                        )}
                                    </Button>
                                </div>
                            ) : (
                                <div className="text-center">
                                    <Button
                                        disabled={disabled}
                                        className="my-4"
                                        color="primary"
                                        type="button"
                                        onClick={() => {
                                            handleSendOtp(email);
                                        }}>
                                        {!loading ? (
                                            'Send OTP'
                                        ) : (
                                            <Spinner size="sm" />
                                        )}
                                    </Button>
                                </div>
                            )}
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </>
    ) : (
        <>
            <ToastContainer />
            <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <Form role="form">
                            {password1 && (
                                <div className="text-muted font-italic">
                                    <small>
                                        password strength:{' '}
                                        {(passStrength === 1 && (
                                            <span className="text-danger font-weight-700">
                                                weak
                                            </span>
                                        )) ||
                                            (passStrength === 2 && (
                                                <span className="text-info font-weight-700">
                                                    moderate
                                                </span>
                                            )) ||
                                            (passStrength === 3 && (
                                                <span className="text-success font-weight-700">
                                                    strong
                                                </span>
                                            ))}
                                    </small>
                                </div>
                            )}

                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="New Password"
                                        type="password"
                                        autoComplete="new-password"
                                        value={password1}
                                        onChange={(event) => {
                                            setPassword1(event.target.value);
                                            checkComplete();
                                            checkPasswordStrength(
                                                event.target.value
                                            );
                                        }}
                                    />
                                </InputGroup>
                            </FormGroup>

                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Re-Enter Password"
                                        type="password"
                                        autoComplete="new-password"
                                        value={password2}
                                        onChange={(event) => {
                                            setPassword2(event.target.value);
                                            checkComplete();
                                        }}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <div className="text-center">
                                <Button
                                    disabled={passDisabled}
                                    className="my-4"
                                    color="primary"
                                    type="button"
                                    onClick={() =>
                                        handleClick(password1, password2)
                                    }>
                                    {!loading ? (
                                        'Set Password'
                                    ) : (
                                        <Spinner size="sm" />
                                    )}
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default ForgotPassword;
