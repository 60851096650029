import { useContext, useEffect, useState } from 'react';
import Chart from 'chart.js';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
// reactstrap components
import {
    Button,
    Badge,
    Card,
    CardHeader,
    CardImg,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Form,
    FormGroup,
    Label,
    Input,
    Table,
    Container,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Pagination,
    InputGroup,
    InputGroupText,
    Spinner,
} from 'reactstrap';

// core components
import {
    chartOptions,
    parseOptions,
    chartExample1,
    chartExample2,
} from 'variables/charts.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Header from 'components/Headers/Header.js';
import { AuthContext } from 'utils/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TiPencil, TiTrash, TiPlus } from 'react-icons/ti';
import { BASE_URL } from 'utils/config';
import ReactPaginate from 'react-paginate';
import '../css/pagination.css';
import { useNavbarContext } from 'components/Navbars/NavbarContext';
import Select from 'react-select';
import './overlay.css';
import loadImage from 'blueimp-load-image';

const Attendance = (props) => {
    const {
        getAttendance,
        attendanceList,
        getCompanyLocations,
        companyLocations,
        addTask,
        editTask,
        deleteTask,
        usersList,
        taskTypes,
        taskList,
        getTaskTypes,
        getTasksList,
        getUsersList,
        toastrMsg,
        toastrError,
        setToastrError,
        setToastrMsg,
        pages,
        changeStatus,
        alottAttendance,
    } = useContext(AuthContext);
    const { setPageTitle } = useNavbarContext();
    const [filterUser, setFilterUser] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [filterDate, setFilterDate] = useState('');
    const [page, setPage] = useState(0);
    const [options, setOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);
    const [user, setUser] = useState(null);
    const [location, setLocation] = useState(null);
    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(false);

    const [edit, setEdit] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const statusOptions = [
        {
            value: '1',
            label: 'Approved',
            customLabel: (
                <Button ouline color="success" size="sm">
                    Approved
                </Button>
            ),
        },
        {
            value: '2',
            label: 'Rejected',
            customLabel: (
                <Button ouline color="danger" size="sm">
                    Rejected
                </Button>
            ),
        },
    ];

    if (window.Chart) {
        parseOptions(Chart, chartOptions());
    }

    useEffect(() => {
        document.title = 'Attendance';
        setPageTitle('Attendance Log');
        // setLoading(true);
        getAttendance();

        getUsersList();
        getCompanyLocations();
    }, []);

    useEffect(() => {
        if (attendanceList) {
            // setLoading(true);
        }
    }, [attendanceList]);

    useEffect(() => {
        if (usersList) {
            setOptions(
                usersList.map((x) => {
                    return {
                        value: x.id,
                        label: `${x.first_name} ${x.last_name}`,
                    };
                })
            );
        }
    }, [usersList]);

    useEffect(() => {
        if (companyLocations) {
            setLocationOptions([
                { value: '', customLabel: 'Anywhere' },
                ...companyLocations?.locations.map((x) => {
                    return {
                        value: x.id,
                        customLabel: (
                            <div>
                                <b>{x.name}</b> - {x.description}
                            </div>
                        ),
                    };
                }),
            ]);
        }
    }, [companyLocations]);

    useEffect(() => {
        if (toastrMsg) {
            toast.success(toastrMsg);
            setToastrMsg(null);
            init();
            toggle();
        } else if (toastrError) {
            toast.error(toastrError);
            setToastrError(null);
        }

        setLoading(false);
    }, [toastrMsg, toastrError, setToastrMsg, setToastrError]);

    function convertDateFormat(inputDate) {
        if (inputDate) {
            const dateParts = inputDate.split('/');

            const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;

            return formattedDate;
        }
        return inputDate;
    }

    function formatDateToCustomFormat(isoDate) {
        const options = {
            day: 'numeric',
            month: 'long',
            hour: '2-digit',
            year: 'numeric',
            minute: '2-digit',
            second: '2-digit',
        };

        const date = new Date(isoDate);
        const formattedDate = date.toLocaleString('en-US', options);

        return formattedDate;
    }

    const handleDateChange = (dateString) => {
        const date = new Date(dateString);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        setFilterDate(`${day}/${month}/${year}`);
    };

    const parseLocationAndRenderLink = (location) => {
        try {
            const parsedLocation = location;

            const lat = parsedLocation?.geocodes?.lat || 0;
            const lng = parsedLocation?.geocodes?.lng || 0;

            if (!lat || !lng) {
                return 'Unknown Location';
            }

            return (
                <a
                    href={`https://www.google.com/maps?q=${lat},${lng}&hl=en`}
                    target="_blank"
                    rel="noopener noreferrer">
                    {parsedLocation?.name
                        ? parsedLocation.name
                        : 'View in Google Maps'}
                </a>
            );
        } catch (error) {
            console.error('Error parsing location JSON:', error);
            return 'Unknown';
        }
    };

    const renderLink = (location) => {
        try {
            const parsedLocation = location;
            const lat = parsedLocation?.geocodes?.lat || 0;
            const lng = parsedLocation?.geocodes?.lng || 0;

            if (!lat || !lng) {
                return 'Unknown Location';
            }

            return `https://www.google.com/maps?q=${lat},${lng}&hl=en`;
        } catch (error) {
            console.error('Error parsing location JSON:', error);
            return 'Unknown';
        }
    };

    const formatOptionLabel = ({ label, customLabel }) => customLabel;

    const init = () => {
        setUser('');
        setLocation(null);
        setTime('');
        setDate('');
    };

    const handleSubmit = () => {
        if (!user || !date || !time) {
            toast.error('Fill in all the fields');
            setLoading(false);
            return;
        }
        const formData = new FormData();
        formData.append('user', user);
        formData.append('location', location);
        formData.append('date', date);
        formData.append('time', time);
        alottAttendance(formData, page, filterUser, filterDate);
        init();
    };

    const exportToExcel = () => {
        setLoading(true);
        // Create a new workbook and a worksheet
        const workbook = XLSX.utils.book_new();

        // Define the headers
        const headers = [
            { name: 'Name', key: 'name' },
            { name: 'Date', key: 'created_at' },
            { name: 'Location', key: 'locationUrl' },
            { name: 'Image', key: 'imageUrl' },
            { name: 'Status', key: 'status' },
            { name: 'Added By', key: 'addedBy' },
        ];

        // Map the headers to the format needed by XLSX
        const headerNames = headers.map((header) => header.name);

        // Add headers as the first row
        const worksheetData = [
            headerNames,
            ...attendanceList.map((item) => [
                `${item.user.first_name} ${item.user.last_name}`,
                formatDateToCustomFormat(item.created_at),
                (item.location && item.location.name) ||
                    (item.unknown_location && 'Offline') ||
                    'Unknown Location',
                item.img_attendance ? 'View' : 'Image not available',
                (item.status === '1' && 'Approved') ||
                    (item.status === '2' && 'Rejected'),
                item.added_by.id === item.user.id
                    ? `${item.user.first_name} ${item.user.last_name}`
                    : `${item.added_by.first_name} ${item.added_by.last_name} (Admin)`,
            ]),
        ];

        // Convert the data to a worksheet
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

        attendanceList.forEach((item, index) => {
            const rowIndex = index + 2; // Data rows start from 2 (1-based index)

            if (item.location) {
                worksheet[`C${rowIndex}`].l = {
                    Target: renderLink(item.location),
                    Tooltip: item.location.name,
                };
            } else if (item.unknown_location) {
                worksheet[`C${rowIndex}`].l = {
                    Target: `https://www.google.com/maps?q=${item?.unknown_location?.lat},${item.unknown_location?.lng}&hl=en`,
                    Tooltip: 'Offline',
                };
            }

            if (item.img_attendance) {
                worksheet[`D${rowIndex}`].l = {
                    Target: `${BASE_URL}${item?.img_attendance}`,
                    Tooltip: 'View',
                };
            }
        });

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Attendance Log');

        // Write the workbook to a binary string
        const excelBuffer = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        // Create a Blob from the binary string and save it
        const blob = new Blob([excelBuffer], {
            type: 'application/octet-stream',
        });
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        saveAs(blob, `attendance_log-${formattedDate}.xlsx`);
        setLoading(false);
    };

    return (
        <>
            <Header />

            <Container className="mt-4" fluid>
                <Row>
                    <ToastContainer />
                    {attendanceList && !loading ? (
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card
                                className="shadow"
                                style={{ height: '42rem' }}>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center justify-content-between">
                                        <Form className="d-flex flex-row  align-items-center w-50">
                                            <FormGroup
                                                className="mr-4"
                                                style={{ flex: 4 }}>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="filter-user">
                                                    User
                                                </label>
                                                <Select
                                                    styles={{
                                                        container: (
                                                            provided
                                                        ) => ({
                                                            ...provided,
                                                            zIndex: 11, // Ensure the select dropdown is above the header
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            zIndex: 20, // Ensure the select dropdown menu is above everything
                                                        }),
                                                    }}
                                                    name="filter_user"
                                                    size="sm"
                                                    options={options}
                                                    value={
                                                        filterUser
                                                            ? options.find(
                                                                  (option) =>
                                                                      option.value ===
                                                                      filterUser
                                                              )
                                                            : ''
                                                    }
                                                    className="form-control-alternative"
                                                    classNamePrefix="select"
                                                    onChange={(e) => {
                                                        setFilterUser(e.value);
                                                    }}
                                                />
                                            </FormGroup>
                                            <FormGroup
                                                className="mr-4"
                                                style={{ flex: 3 }}>
                                                <label
                                                    style={{ display: 'block' }}
                                                    className="form-control-label"
                                                    htmlFor="filter-status">
                                                    Date
                                                </label>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle className="p-0 dropdown-menu-arrow">
                                                        <Input
                                                            className="form-control-alternative"
                                                            placeholder="dd/mm/yyy"
                                                            size="sm"
                                                            value={filterDate}
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                setFilterDate(
                                                                    event.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                    </DropdownToggle>

                                                    <DropdownMenu
                                                        style={{
                                                            width: 'auto',
                                                            padding: '10px',
                                                            transform:
                                                                'translateX(-50%)',
                                                            left: '50%',
                                                            top: '100%',
                                                            marginTop: '5px',
                                                            position:
                                                                'absolute',
                                                            zIndex: 1000,

                                                            overflowY: 'auto',
                                                        }}>
                                                        <Calendar
                                                            direction="vertical"
                                                            onChange={
                                                                handleDateChange
                                                            }
                                                        />
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </FormGroup>

                                            <Button
                                                className="form-control-alternative mt-2 ml--1"
                                                color="primary"
                                                size="sm"
                                                onClick={(e) => {
                                                    e.preventDefault();

                                                    getAttendance({
                                                        page: page + 1,
                                                        user: filterUser,
                                                        date: convertDateFormat(
                                                            filterDate
                                                        ),
                                                    });

                                                    setPage(0);
                                                }}>
                                                Filter
                                            </Button>

                                            <Button
                                                className="form-control-alternative mt-2"
                                                color="primary"
                                                size="sm"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setFilterUser('');
                                                    setFilterDate('');
                                                    getAttendance();
                                                }}>
                                                Reset
                                            </Button>
                                        </Form>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Modal
                                                isOpen={isOpen}
                                                toggle={toggle}>
                                                <ModalHeader toggle={toggle}>
                                                    Mark Attendance For
                                                </ModalHeader>
                                                <ModalBody>
                                                    <Form>
                                                        <div className="pl-lg-4">
                                                            <Row>
                                                                <Col lg="12">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-User">
                                                                            User
                                                                        </label>
                                                                        <Select
                                                                            required={
                                                                                true
                                                                            }
                                                                            name="user"
                                                                            size="sm"
                                                                            options={
                                                                                options
                                                                            }
                                                                            value={options.find(
                                                                                (
                                                                                    option
                                                                                ) =>
                                                                                    option.value ===
                                                                                    user
                                                                            )}
                                                                            className="form-control-alternative"
                                                                            classNamePrefix="select"
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                setUser(
                                                                                    e.value
                                                                                );
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="12">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-Location">
                                                                            Location
                                                                        </label>
                                                                        <Select
                                                                            isSearchable={
                                                                                false
                                                                            }
                                                                            required={
                                                                                true
                                                                            }
                                                                            name="locations"
                                                                            size="sm"
                                                                            value={locationOptions.find(
                                                                                (
                                                                                    option
                                                                                ) =>
                                                                                    option.value ===
                                                                                    location
                                                                            )}
                                                                            options={
                                                                                locationOptions
                                                                            }
                                                                            formatOptionLabel={
                                                                                formatOptionLabel
                                                                            }
                                                                            className="form-control-alternative"
                                                                            classNamePrefix="select"
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                setLocation(
                                                                                    e.value
                                                                                );
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-Location">
                                                                            Date
                                                                        </label>
                                                                        <Input
                                                                            type="date"
                                                                            name="date"
                                                                            required={
                                                                                true
                                                                            }
                                                                            id="date"
                                                                            className="form-control-alternative"
                                                                            value={
                                                                                date
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                setDate(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                );
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="input-Location">
                                                                            Time
                                                                        </label>
                                                                        <Input
                                                                            type="time"
                                                                            name="time"
                                                                            required={
                                                                                true
                                                                            }
                                                                            id="time"
                                                                            className="form-control-alternative"
                                                                            value={
                                                                                time
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                setTime(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                );
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <ModalFooter>
                                                                <Button
                                                                    color="danger"
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.preventDefault();
                                                                        toggle();
                                                                    }}
                                                                    size="sm">
                                                                    Cancel
                                                                </Button>
                                                                <Button
                                                                    color="primary"
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.preventDefault();
                                                                        setLoading(
                                                                            true
                                                                        );
                                                                        handleSubmit();
                                                                    }}
                                                                    size="sm">
                                                                    Create
                                                                </Button>
                                                            </ModalFooter>
                                                        </div>
                                                    </Form>
                                                </ModalBody>
                                            </Modal>
                                            <Button
                                                outline
                                                color="info"
                                                size="sm"
                                                className="float-right mx-2"
                                                onClick={exportToExcel}>
                                                Download
                                            </Button>
                                            <Button
                                                outline
                                                color="info"
                                                size="sm"
                                                className="float-right"
                                                onClick={toggle}>
                                                Create Attendance
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <div
                                    style={{
                                        height: '100%',
                                        overflowY: 'auto',
                                    }}>
                                    <Table
                                        // className="align-items-center"
                                        style={{}}>
                                        <thead
                                            className="thead-light"
                                            style={{
                                                position: 'sticky',
                                                top: '0',
                                                zIndex: '1',
                                            }}>
                                            <tr>
                                                <th scope="col">User</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Location</th>
                                                <th scopr="col">Image</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Added By</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {attendanceList &&
                                                attendanceList.map((x) => {
                                                    return (
                                                        <tr>
                                                            <th scope="row">{`${x.user.first_name} ${x.user.last_name}`}</th>
                                                            <td scope="row">
                                                                {formatDateToCustomFormat(
                                                                    `${x.date}T${x.time}`
                                                                )}
                                                            </td>
                                                            <td scope="row">
                                                                {(x.location &&
                                                                    parseLocationAndRenderLink(
                                                                        x.location
                                                                    )) ||
                                                                    (x.unknown_location ? (
                                                                        <a
                                                                            href={`https://www.google.com/maps?q=${x?.unknown_location?.lat},${x.unknown_location?.lng}&hl=en`}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer">
                                                                            {
                                                                                'Offline'
                                                                            }
                                                                        </a>
                                                                    ) : (
                                                                        'Unknown'
                                                                    ))}
                                                            </td>
                                                            <td>
                                                                {x.img_attendance ? (
                                                                    <a
                                                                        href={`${BASE_URL}${x?.img_attendance}`}
                                                                        target="_blank">
                                                                        <CardImg
                                                                            alt="Card image cap"
                                                                            src={`${BASE_URL}${x?.img_attendance}`}
                                                                            style={{
                                                                                height: 50,
                                                                                width: 50,
                                                                            }}
                                                                            top
                                                                            width=""
                                                                        />
                                                                    </a>
                                                                ) : (
                                                                    'Unvailable'
                                                                )}
                                                            </td>

                                                            <td>
                                                                <Select
                                                                    options={
                                                                        statusOptions
                                                                    }
                                                                    isSearchable={
                                                                        false
                                                                    }
                                                                    className="form-control-alternative"
                                                                    classNamePrefix="select"
                                                                    defaultValue={statusOptions.find(
                                                                        (
                                                                            option
                                                                        ) =>
                                                                            option.value ===
                                                                            x.status
                                                                    )}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        changeStatus(
                                                                            x.id,
                                                                            page +
                                                                                1,
                                                                            filterUser,
                                                                            convertDateFormat(
                                                                                filterDate
                                                                            )
                                                                        );
                                                                    }}
                                                                    formatOptionLabel={
                                                                        formatOptionLabel
                                                                    }
                                                                />
                                                            </td>
                                                            <td>
                                                                {
                                                                    x.added_by
                                                                        .first_name
                                                                }{' '}
                                                                {
                                                                    x.added_by
                                                                        .last_name
                                                                }{' '}
                                                                {x.added_by
                                                                    .id !==
                                                                x.user.id
                                                                    ? '(Admin)'
                                                                    : ''}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </Table>
                                    {pages > 1 && (
                                        <div
                                            className="d-flex flex-row justify-content-center align-items-center mt-2"
                                            size="sm">
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel="Next"
                                                previousLabel="Previous"
                                                forcePage={page}
                                                onPageChange={(e) => {
                                                    setPage(e.selected);

                                                    getAttendance({
                                                        page: e.selected + 1,
                                                        user: filterUser,
                                                        date: convertDateFormat(
                                                            filterDate
                                                        ),
                                                    });
                                                }}
                                                pageCount={pages}
                                                marginPagesDisplayed={2}
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                breakClassName="break"
                                                size="sm"
                                            />
                                        </div>
                                    )}
                                </div>
                            </Card>
                        </Col>
                    ) : (
                        <div className="overlay">
                            <Spinner
                                style={{ width: '3rem', height: '3rem' }}
                                color="primary"
                            />
                        </div>
                    )}
                </Row>
            </Container>
        </>
    );
};

export default Attendance;
