// import React, { useEffect, useState } from 'react';
// import { Navigate } from 'react-router-dom';

// const userInfo = JSON.parse(localStorage.getItem('userInfo'));

// export const AuthenticatedRoute = ({ children }) => {
//     const [token, setToken] = useState(() => {
//         const userInfo = JSON.parse(localStorage.getItem('userInfo'));

//         return userInfo ? userInfo.token : null;
//     });

//     const [userType, setUserType] = useState(() => {
//         const userInfo = JSON.parse(localStorage.getItem('userInfo'));

//         return userInfo ? userInfo.user_type : null;
//     });

//     useEffect(() => {
//         const userInfo = JSON.parse(localStorage.getItem('userInfo'));
//         if (userInfo) {
//             setUserType(userInfo.user_type);
//             setToken(userInfo.token);
//         }
//     }, []);

//     return token && userType === '1' ? (
//         <>{children}</>
//     ) : (
//         <Navigate to="/auth/login" />
//     );
// };

// export const NonAdminAuthenticatedRoute = ({ children }) => {
//     const [token, setToken] = useState(() => {
//         const userInfo = JSON.parse(localStorage.getItem('userInfo'));

//         return userInfo ? userInfo.token : null;
//     });

//     const [userType, setUserType] = useState(() => {
//         const userInfo = JSON.parse(localStorage.getItem('userInfo'));

//         return userInfo ? userInfo.user_type : null;
//     });

//     useEffect(() => {
//         const userInfo = JSON.parse(localStorage.getItem('userInfo'));
//         if (userInfo) {
//             setToken(userInfo.token);
//         }
//     }, []);

//     return token ? <>{children}</> : <Navigate to="/auth/login" />;
// };

import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

// export const AuthenticatedRoute = ({ children }) => {
//     const [token, setToken] = useState(null);
//     useEffect(() => {
//         const userInfo = JSON.parse(localStorage.getItem('userInfo'));
//         if (userInfo) {
//             setToken(userInfo?.token);
//         }
//     });
//     return token ? <>{children}</> : <Navigate to="/login" />;
// };

export const AuthenticatedRoute = ({ children }) => {
    const [userType, setUserType] = useState(() => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        return userInfo ? userInfo.user_type : null;
    });
    const [token, setToken] = useState(() => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        return userInfo ? userInfo.token : null;
    });

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo) {
            setUserType(userInfo.user_type);
            setToken(userInfo.token);
        }
    }, []); // Empty dependency array to run only once

    return token && userType === '2' ? children : <Navigate to="/auth/login" />;
};

export const PublicRoute = ({ children }) => {
    const [token, setToken] = useState(null);
    const [userType, setUserType] = useState(() => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        return userInfo ? userInfo.user_type : null;
    });

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo) {
            setToken(userInfo?.token);
            setUserType(userInfo?.user_type);
        }
    }, []);

    return (
        (token && userType === '1' && <Navigate to="/admin/dashboard" />) ||
        (token && userType === '2' && <Navigate to="/mark-attendance" />) ||
        children
    );
};

export const AdminRoute = ({ children }) => {
    const [userType, setUserType] = useState(() => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        return userInfo ? userInfo.user_type : null;
    });

    const [token, setToken] = useState(() => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));

        return userInfo ? userInfo.token : null;
    });

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (userInfo) {
            setUserType(userInfo.user_type);
            setToken(userInfo?.token);
        }
    }, []); // Empty dependency array to run only once

    return token && userType === '1' ? children : <Navigate to="/auth/login" />;
};
