// reactstrap components
import React, {
    useState,
    useEffect,
    useContext,
    useDeferredValue,
} from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    InputGroup,
    Spinner,
} from 'reactstrap';

import UserHeader from 'components/Headers/UserHeader.js';
import Header from 'components/Headers/Header';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from 'utils/AuthContext';
import { BASE_URL } from 'utils/config';
import { TiPencil, TiTrash, TiPlus } from 'react-icons/ti';
import { useLoadScript } from '@react-google-maps/api';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption,
} from '@reach/combobox';
import '@reach/combobox/styles.css';
import { ColumnSorting } from 'handsontable/plugins';
import {
    collapseTextChangeRangesAcrossMultipleVersions,
    isJsxClosingFragment,
} from 'typescript';
import PlacesAutocomplete from './placesAutocomplete';
import { useNavbarContext } from 'components/Navbars/NavbarContext';

const CompanyProfile = () => {
    const { setPageTitle } = useNavbarContext();
    const {
        toastrMsg,
        toastrError,
        setToastrError,
        setToastrMsg,
        getCompanyDetails,
        companyData,
        editCompanyInfo,
        editComapanyContactInfo,
    } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [industry, setIndustry] = useState('');
    const [website, setWebsite] = useState('');
    const [logo, setLogo] = useState({});
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [pin, setPin] = useState('');
    const [locations, setLocations] = useState([]);
    const [description, setDescription] = useState('');
    const [inputs, setInputs] = useState([]);

    useEffect(() => {
        document.title = 'Company Profile';
        setPageTitle('Company Settings');
        getCompanyDetails();
    }, []);

    useEffect(() => {
        if (companyData) {
            setInputs([]);

            setIndustry(companyData?.industry || '');
            setWebsite(companyData?.website || '');
            setName(companyData?.company_name || '');
            setAddress(companyData?.address);
            setCity(companyData?.city);
            setState(companyData?.state);
            setCountry(companyData?.country);
            setPin(companyData?.pin);
            // companyData?.locations?.forEach((x, i) => {
            //     return (x['id'] = i);
            // });
            setLocations(
                Array.isArray(companyData?.locations)
                    ? companyData?.locations
                    : []
            );
        }
    }, [companyData, companyData?.locations]);

    useEffect(() => {
        if (toastrMsg) {
            toast.success(toastrMsg);
            setToastrMsg(null);
        } else if (toastrError) {
            toast.error(toastrError);
            setToastrError(null);
        }
        setLoading(false);
    }, [toastrMsg, toastrError, setToastrMsg, setToastrError]);

    //////////////////////////////////////////////////////////////////////////////////////////////

    const handleContactInfoChange = () => {
        setLoading(true);
        const formData = new FormData();
        if (logo) {
            formData.append('logo', logo);
        }
        formData.append('website', website);
        formData.append('industry', industry);
        formData.append('name', name);
        formData.append('locations', JSON.stringify(locations));

        editCompanyInfo(formData);
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////////////

    const handleLocationSelect = ({ name = null, newLocation = null }) => {
        let updatedLocations = [...locations];
        updatedLocations[newLocation.id].description = newLocation.description;
        updatedLocations[newLocation.id].geocodes = newLocation.geocodes;

        setLocations(updatedLocations);
    };

    const handleInputsChange = () => {
        setLocations([
            ...locations,
            { description: '', geocodes: '', name: '' },
        ]);
    };

    const handleLocationDelete = (obj, index) => {
        if (obj.id !== undefined && obj.id !== null) {
            const updatedLocations = locations.map((location) => {
                if (location.id === obj.id) {
                    return { ...location, delete: true, hide: true };
                }
                return location;
            });
            setLocations(updatedLocations);
        } else {
            setLocations((prevLocations) => {
                const updatedLocations = [...prevLocations];
                updatedLocations.splice(index, 1);
                return updatedLocations;
            });
        }
    };

    const handleLocationNameChange = (index, value) => {
        const updatedLocations = [...locations];
        updatedLocations[index].name = value;
        setLocations(updatedLocations);
    };
    return (
        <>
            <Header />
            <ToastContainer />
            {companyData && !loading ? (
                <Container style={{ marginTop: '2rem' }} fluid>
                    <Row>
                        <Col className="order-xl-1" xl="12">
                            {/* <Card className="bg-secondary shadow">
                                <CardBody> */}
                            <Form>
                                <Row>
                                    <Col xl="6">
                                        <Card className="bg-white shadow">
                                            <CardBody>
                                                <h6 className="heading-small text-muted mb-4">
                                                    Basic Information
                                                </h6>
                                                <div className="pl-lg-4">
                                                    <Row>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-company-name">
                                                                    Company Name
                                                                </label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    id="input-company-name"
                                                                    placeholder="Company Name"
                                                                    value={name}
                                                                    type="text"
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        setName(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-industry">
                                                                    Industry
                                                                </label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    id="input-industry"
                                                                    placeholder="Industry"
                                                                    type="text"
                                                                    value={
                                                                        industry
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        setIndustry(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-website">
                                                                    Website
                                                                </label>
                                                                <Input
                                                                    className="form-control-alternative"
                                                                    id="input-website"
                                                                    placeholder="Website"
                                                                    type="url"
                                                                    value={
                                                                        website
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        setWebsite(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroup>
                                                                <label
                                                                    className="form-control-label"
                                                                    htmlFor="input-logo">
                                                                    Company Logo{' '}
                                                                    <small>
                                                                        <a
                                                                            href={
                                                                                companyData?.logo
                                                                                    ? `${BASE_URL}${companyData?.logo}`
                                                                                    : `${BASE_URL}/media/company_logo.png`
                                                                            }
                                                                            target="_blank">
                                                                            View
                                                                        </a>
                                                                    </small>
                                                                </label>
                                                                <Input
                                                                    className="form-control"
                                                                    id="input-logo"
                                                                    type="file"
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        setLogo(
                                                                            event
                                                                                .target
                                                                                .files[0]
                                                                        );
                                                                    }}></Input>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xl="6">
                                        <Card className="bg-white shadow">
                                            <CardBody>
                                                <h6 className="heading-small text-muted mb-4">
                                                    Locations
                                                </h6>
                                                <div className="pl-lg-4">
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup>
                                                                {locations &&
                                                                    locations.map(
                                                                        (
                                                                            x,
                                                                            i
                                                                        ) => {
                                                                            return (
                                                                                !x.hide && (
                                                                                    <div className="d-flex align-items-center mb-2">
                                                                                        <div
                                                                                            className="mr-2"
                                                                                            style={{
                                                                                                flex: 1,
                                                                                            }}>
                                                                                            <Input
                                                                                                className="form-control-alternative"
                                                                                                placeholder="Location Name"
                                                                                                value={
                                                                                                    x.name
                                                                                                }
                                                                                                onChange={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    handleLocationNameChange(
                                                                                                        i,
                                                                                                        e
                                                                                                            .target
                                                                                                            .value
                                                                                                    )
                                                                                                }
                                                                                                type="text"
                                                                                            />
                                                                                        </div>
                                                                                        <div
                                                                                            className="mr-2"
                                                                                            style={{
                                                                                                flex: 3,
                                                                                            }}>
                                                                                            <PlacesAutocomplete
                                                                                                style={{
                                                                                                    width: '100%',
                                                                                                }}
                                                                                                location={
                                                                                                    x
                                                                                                }
                                                                                                addressData={{
                                                                                                    index: i,

                                                                                                    ...x,
                                                                                                }}
                                                                                                index={{
                                                                                                    type: 'prev',
                                                                                                    id: x.id,
                                                                                                }}
                                                                                                onLocationSelect={
                                                                                                    handleLocationSelect
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                        <TiTrash
                                                                                            style={{
                                                                                                cursor: 'pointer',
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                handleLocationDelete(
                                                                                                    x,
                                                                                                    i
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            );
                                                                        }
                                                                    )}
                                                                {/* {inputs.map(
                                                                    (x, i) => {
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    i
                                                                                }
                                                                                className="d-flex align-items-center mb-2">
                                                                                <div
                                                                                    className="mr-2"
                                                                                    style={{
                                                                                        flex: 3,
                                                                                    }}>
                                                                                    <PlacesAutocomplete
                                                                                        style={{
                                                                                            width: '100%',
                                                                                        }}
                                                                                        // location={x}
                                                                                        index={{
                                                                                            type: 'new',
                                                                                            id: i,
                                                                                        }}
                                                                                        onLocationSelect={
                                                                                            handleLocationSelect
                                                                                        }
                                                                                    />
                                                                                </div>

                                                                                <TiTrash
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                    onClick={() =>
                                                                                        handleNewLocationDelete(
                                                                                            i
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        );
                                                                    }
                                                                )} */}
                                                                <InputGroup>
                                                                    <Button
                                                                        className="float-left btn-outline-primary"
                                                                        // color="primary"
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.preventDefault();
                                                                            handleInputsChange();
                                                                        }}
                                                                        size="sm">
                                                                        <span>
                                                                            ➕
                                                                        </span>{' '}
                                                                        Add
                                                                    </Button>
                                                                </InputGroup>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>

                                <Row className="d-flex flex-row justify-content-center mt-5">
                                    <Col lg="12" className="text-center">
                                        <Button
                                            color="primary"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                // handleSaveAllChanges();
                                                handleContactInfoChange();
                                            }}
                                            size="sm">
                                            Save All Changes
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                            {/* </CardBody>
                            </Card> */}
                        </Col>
                    </Row>
                </Container>
            ) : (
                <div className="overlay">
                    <Spinner
                        style={{ width: '3rem', height: '3rem' }}
                        color="primary"
                    />
                </div>
            )}
        </>
    );
};

export default CompanyProfile;
