import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './index.css';
import 'assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/argon-dashboard-react.scss';

import AdminLayout from 'layouts/Admin.js';
import AuthLayout from 'layouts/Auth.js';
import NonAdminLayout from 'layouts/NonAdmin';
import Home from 'views/examples/Home';
// import Admin from "layouts/Admin";
import { AuthProvider } from 'utils/AuthContext';
import { Loader } from '@googlemaps/js-api-loader';
import {
    AuthenticatedRoute,
    NonAdminAuthenticatedRoute,
} from 'routes/protectedRoutes';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <AuthProvider>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/admin/*" element={<AdminLayout />} />
                <Route path="/auth/*" element={<AuthLayout />} />
                <Route path="/*" element={<NonAdminLayout />} />
                <Route
                    path="*"
                    element={<Navigate to="/auth/login" replace />}
                />
            </Routes>
        </BrowserRouter>
    </AuthProvider>
);
