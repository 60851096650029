/*!

=========================================================
* Argon Dashboard React - v1.2.3
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Spinner,
} from 'reactstrap';
import { jwtDecode } from 'jwt-decode';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from 'utils/AuthContext';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import logo from '../../assets/img/home/logo.png';

const Register = () => {
    const [params] = useSearchParams();
    const signedIn = params.get('signedIn');
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [showOtp, setShowOtp] = useState(false);
    const [questions, setQuestions] = useState(false);
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [qDisabled, setQDisabled] = useState(true);
    const [gRegistered, setGRegistered] = useState(false);

    const {
        register,
        isRegistered,
        token,
        verifyOtp,
        toastrMsg,
        toastrError,
        setToastrMsg,
        setToastrError,
        checkEmail,
        hasGSigned,
        checkGRegister,
        googleRegistered,
        setIsRegistered,
    } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Register';
        return () => {
            setGRegistered(false);
        };
    }, []);

    const handleOtp = () => {
        verifyOtp(token, otp);
    };

    const emailValidator = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const handleRegister = (gSign) => {
        setDisabled(true);
        setLoading(true);
        if (name !== '' && company !== '' && email !== '') {
            if (gSign) {
                register(email.trim(), name.trim(), company.trim(), gSign);
            } else {
                if (emailValidator(email)) {
                    register(email.trim(), name.trim(), company.trim());
                } else {
                    toast.error('Enter a valid email');
                    setLoading(false);
                }
            }

            // navigate("/auth/register/signedIn");
        }
    };

    function checkComplete() {
        if (email !== '' && name !== '' && company != '') {
            setDisabled(false);
            setQDisabled(false);
        } else {
            setDisabled(true);
            setQDisabled(true);
        }
    }

    useEffect(() => {
        checkComplete();
    }, [email, name, company]);

    useEffect(() => {
        if (googleRegistered) {
            navigate('/admin/dashboard');
        }
    }, [googleRegistered]);

    const handleOnSuccess = (response) => {
        const idToken = response?.credential;
        if (idToken) {
            const decodeToken = jwtDecode(idToken);
            const email = decodeToken?.email;
            const firstName = decodeToken?.given_name || '';
            const lastName = decodeToken?.family_name || '';
            setEmail(email);
            setName(`${firstName} ${lastName}`);

            checkGRegister(email);
        }
        // const returnedResponse = response?.profileObj;
        // const returnedEmail = returnedResponse?.email;

        // setEmail(returnedEmail);
        // const firstName = returnedResponse?.givenName || '';
        // const lastName = returnedResponse?.firstName || '';

        // setName(`${firstName} ${lastName}`);
        // // setEmail(returnedEmail);
        // localStorage.setItem('userEmail', returnedEmail);
        // checkGRegister(returnedEmail);
    };

    // useEffect(() => {
    //     if (hasGSigned && hasGSigned === 'yes') {

    //         navigate('/admin/dashboard');
    //         localStorage.removeItem('userEmail');
    //     } else if (hasGSigned && hasGSigned === 'no') {

    //         // navigate("/auth/onboarding");
    //         // setQuestions(true);
    //         navigate('/auth/onboarding');
    //     }
    // }, [hasGSigned]);

    useEffect(() => {
        if (hasGSigned === 'yes') {
            setGRegistered(true);
        } else if (hasGSigned === 'no') {
            setGRegistered(false);
            setEmail('');
            setName('');
            setCompany('');
        }
    }, [hasGSigned]);

    const handleOnFailure = (response) => {};

    useEffect(() => {
        if (isRegistered) {
            navigate('/auth/registered');
        }
    }, [isRegistered]);

    useEffect(() => {
        if (toastrMsg) {
            toast.success(toastrMsg);
            setToastrMsg(null);
            setGRegistered(false);
        } else if (toastrError) {
            setEmail('');
            toast.error(toastrError);
            setToastrError(null);
        }

        setLoading(false);
        setDisabled(true);
        setQDisabled(true);
    }, [toastrMsg, toastrError, setToastrMsg, setToastrError]);
    return (
        <>
            <ToastContainer />
            {!gRegistered ? (
                <Col lg="6" md="8">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-transparent pb-5">
                            <div
                                style={{ width: '100%', alignSelf: 'center' }}
                                className="text-center">
                                <img src={logo} style={{ width: '200px' }} />
                            </div>
                            <br />
                            <br />
                            <div className="d-flex justify-content-center">
                                <GoogleOAuthProvider clientId="426181317198-llu2ionrgv0kohv2in93tm73vjsbb5ec.apps.googleusercontent.com">
                                    <GoogleLogin
                                        size="large"
                                        text="signup_with"
                                        theme="filled_blue"
                                        itp_support={false}
                                        // type="standard"
                                        className="btn ms-1 my-4 font-weight-bold atlas-cta cta-green"
                                        context="signup"
                                        // use_fedcm_for_prompt={false}
                                        onSuccess={handleOnSuccess}
                                        onError={handleOnFailure}
                                        autoSelect={false}></GoogleLogin>
                                </GoogleOAuthProvider>
                                {/* <GoogleLogin
                                    buttonText="Sign Up with Google"
                                    clientId="426181317198-llu2ionrgv0kohv2in93tm73vjsbb5ec.apps.googleusercontent.com"
                                    onSuccess={handleOnSuccess}
                                    onFailure={handleOnFailure}
                                    isLoggedIn={true}
                                /> */}
                            </div>
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5">
                            <div className="text-center text-muted mb-4">
                                <small>Or sign up with credentials</small>
                            </div>
                            <Form role="form">
                                <FormGroup>
                                    <InputGroup className="input-group-alternative mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-hat-3" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Name"
                                            value={name}
                                            type="text"
                                            onChange={(event) =>
                                                setName(event.target.value)
                                            }
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <InputGroup className="input-group-alternative mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-building" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Company"
                                            value={company}
                                            type="text"
                                            onChange={(event) =>
                                                setCompany(event.target.value)
                                            }
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <InputGroup className="input-group-alternative mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-email-83" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Email"
                                            value={email}
                                            type="email"
                                            onChange={(event) =>
                                                setEmail(event.target.value)
                                            }
                                        />
                                    </InputGroup>
                                </FormGroup>

                                <Row className="mt-3">
                                    <Col className="text-center" xs="12">
                                        <small>Account Created? </small>
                                        <Link to="/auth/login">
                                            <small>Login</small>
                                        </Link>
                                    </Col>
                                </Row>
                                <div className="text-center">
                                    <Button
                                        disabled={disabled}
                                        className="mt-4"
                                        color="primary"
                                        type="button"
                                        onClick={() => handleRegister()}>
                                        {!loading ? (
                                            'Create Account'
                                        ) : (
                                            <>
                                                <Spinner size="sm" />
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            ) : (
                <Col lg="6" md="8">
                    <Card className="bg-secondary shadow border-0">
                        <CardBody className="px-lg-5 py-lg-5">
                            <div className="text-center text-muted mb-4">
                                <small>Answer a Few Questions...</small>
                            </div>
                            <Form role="form">
                                <FormGroup>
                                    <InputGroup className="input-group-alternative mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-hat-3" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Name"
                                            type="text"
                                            value={name}
                                            onChange={(event) =>
                                                setName(event.target.value)
                                            }
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <InputGroup className="input-group-alternative mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-building" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Company"
                                            type="text"
                                            value={company}
                                            onChange={(event) =>
                                                setCompany(event.target.value)
                                            }
                                        />
                                    </InputGroup>
                                </FormGroup>

                                <Row className="mt-3"></Row>
                                <div className="text-center">
                                    <Button
                                        disabled={disabled}
                                        className="mt-4"
                                        color="primary"
                                        type="button"
                                        onClick={() => handleRegister(true)}>
                                        {!loading ? (
                                            'Submit'
                                        ) : (
                                            <>
                                                <Spinner size="sm" />
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            )}
        </>
    );
};

export default Register;
